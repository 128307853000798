import React, { Component, Fragment } from "react";
import "../OverridePricing/OverridePricing.css";
// import ProgressBarFive from "../ProgressBar/ProgressBarFive";
import LabelDropDownInput from "../Inputs/LabelDropDownInput";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";

import LabelInput from "../Inputs/LabelInput";
import DatePicker1 from "../Inputs/DatePicker";
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
import CheckBoxSingle from "../Inputs/CheckBoxSingle/CheckBoxSingle";
import { withRouter } from "react-router-dom";
import dateFormat from "dateformat";
import OverrideCouponsInventoryContainer from "./OverrideCouponsInventory/OverrideCouponsInventoryContainer";
import GetQuotasContractDetailsContainer from "./GetQuotasContractDetails/GetQuotasContractDetailsContainer";
import GetPreviousCouponsContainer from "./GetPreviousCoupons/GetPreviousCouponsContainer";
import GetCouponImagesContainer from "./GetCouponImages/GetCouponImagesContainer";
import { Modal } from "../Cards/PreviewNJTCouponCard";
import { Server } from "./getMaxCouponCreateLimit";
import GetCouponTermsContainer from "./GetCouponTerms/GetCouponTermsContainer";
import GetCouponTypesContainer from "./GetCouponTypes/GetCouponTypesContainer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment"
import {coupon_transaction_subtype_id,ref_status, coupon_transaction_type_id} from 'RefRecordsConstants'
import { failure_messages} from 'Messages'
import validator from 'validator'
import MaterialTimePicker from "../Inputs/TimePicker/MaterialTimePicker";
// import { isDate } from "moment-timezone";
import moment_tz from 'moment-timezone';
import { AcceptVideos } from "../../Config/Config";
import VideoIcon from "../../../public/icons8-video-48.png";
import {Text_Field_Limits} from "../../ConfigConstants"

let Videos= AcceptVideos.split(", ")

const {offer_desc_limit, term_and_conditions_limit} = Text_Field_Limits

// import ReactFileReader from 'react-file-reader';
// import { ROOT_URL, getCookie} from "../../Config/Config"
const monthData1 = [
  { name: "January", value: "01" },
  { name: "February", value: "02" },
  { name: "March", value: "03" },
  { name: "April", value: "04" },
  { name: "May", value: "05" },
  { name: "June", value: "06" },
  { name: "July", value: "07" },
  { name: "August", value: "08" },
  { name: "September", value: "09" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
];

export class OverridePricing extends Component {
  constructor(props) {
    super(props);
   this.clearStates()
    this.getMaxCouponCreateLimit();
    this.state.descriptionPlaceholder = this.props.cookies.get("BusinessProfileData").description
    if( this.state.descriptionPlaceholder &&  this.state.descriptionPlaceholder.trim() !="")
    this.state.descriptionPlaceholder = this.state.descriptionPlaceholder
    else
    this.state.descriptionPlaceholder="This text will appear on your offer card";

    // alert(moment_tz().utcOffset())
    }

  clearStates= ()=>{
    this.state = {
      year: "",
      yearFlag: false,
      yearErrorMsg: "Field Required",
      month: "",
      monthFlag: false,
      monthErrorMsg: "Field Required",
      xquantity:'',
      xquantityFlag:false,
      xquantityErrorMsg:'Field Required',
      yquantity:'',
      yquantityFlag:false,
      yquantityErrorMsg:'Field Required',
      buttonShow:false,
      faceValue: "",
      faceValueFlag: false,
      faceValueErrorMsg: "Field Required",
      couponValue: "",
      couponValueFlag: false,
      couponValueErrorMsg: "Field Required",
      modeType: "",
      modeTypeFlag: false,
      modeTypeErrorMsg: "Field Required",
      minimunDate: new Date(),
      usedMaximumDate: new Date(),
      message: "",
      messageFlag: false,
      messageErrorMsg: "Field Required",
      amountValue: "",
      amountValueFlag: false,
      amountValueErrorMsg: "Field Required",
      costPoints: "",
      costPointsFlag: false,
      IsCostDisabled: false,
      costPointsErrorMsg: "Field Required",
      Quantity: "",
      QuantityFlag: false,
      QuantityErrorMsg: "Field Required",
      usedOnDate: "",
      usedOnDateFlag: false,
      usedOnDateErrorMsg: "Field Required",
      startDate: "",
      startDateFlag: false,
      startDateErrorMsg: "Field Required",
      endDate: "",
      endDateFlag: false,
      endDateErrorMsg: "Field Required",
      canBeUsedStartDate: "",
      canBeUsedStartDateFlag: false,
      canBeUsedStartDateErrorMsg: "Field Required",
      canBeUsedEndDate: "",
      canBeUsedEndDateFlag: false,
      canBeUsedEndDateErrorMsg: "Field Required",
      selectedDay: false,
      showMonday: false,
      showTuesday: false,
      showWednesday: false,
      showThursday: false,
      showFriday: false,
      showSaturday: false,
      showSunday: false,
      RangeCheck: false,
      SpecificDayCheck: true,
      yearData: [],
      monthValues: [],
      faceValueData: [],
      QuotasContractDetails: [],
      modeTypeData: [],
      CouponImage :[],
      CouponLogo :[],
      initialDate: "",
      CouponTerms: "",
      coupon_transaction_type:'',
      maxUnusedQuota: '-',
      showModal: false,
      isLoading: false,
      maxCouponsLimit: "",
      BusinessProfileData: this.props.cookies.get("BusinessProfileData"),
      coupon_transaction_type_id: '',
      coupon_transaction_subtype_id:0,
      coupon_transaction_subtype_errorMsg:"Field Required",
      coupon_transaction_subtype_Flag:false,
      legalese:'',
      offerDescription:'',
      offer_name:"",
      offer_flag:false,
      offer_error:"",
      MonthlyQuota:"-",
      SubTypes:[],
      subTypeIndex:'',
      showSubTypes: false,
      isCodeEnabled:'0',
      CodeEnabledFlag: false,
      CodeEnabledErrorMsg:"Field Required",
      showDiscountCodeSection: false,
      coupon_code:'',
      discountCodeFlag: false,
      discountCodeErrorMsg: "Field Required",
      showURL: false,
      redemptionURL:'',
      redemptionURLFlag:false,
      redemptionURLErrorMsg:'Field Required',
      rewardURL:'',
      rewardURLFlag:false,
      rewardURLErrorMsg:'Field Required',
      walletURL:'',
      walletURLFlag:false,
      walletURLErrorMsg:'Field Required',
      showQueryString:false,
      queryString:'',
      queryStringFlag:false,
      queryStringErrorMsg:'Field Required',
      discountCodeCheckBox:false,
      file_error:"",
      chooseAFile:"",
      accountPayment:[],
      updateActiveMode:false,
      EditCouponData:false,
      isfeaturedOffer:false,
      featuredOfferOrder:null,
      featuredOfferOrderFlag:false,
      featuredOfferErrorMsg: "Field Required",
      startTime:null,
      startTimeWithoutOffset:null,
      startTimeFlag:false,
      startTimeErrMsg:"Field Required",
      endTimeFlag:false,
      endTime:null,
      endTimeWithoutOffset:null,
      endTimeErrMsg:"Field Required",
      canBeUsedStartTime:null,
      canBeUsedStartTimeWithoutOffset:null,
      canBeUsedStartTimeFlag:false,
      getYearQuotas:false,
      maxMediaLimit:5,
      canBeUsedEndTimeFlag:false,
      canBeUsedEndTime:null,
      canBeUsedEndTimeWithoutOffset:null,
      couponImageFlag: false,
      couponImageErrorMsg: "Minimum 1 Image OR Video is required."
    };
  }

  setYearQuotas = (flag, flag2=false)=>{
    this.setState({
      getYearQuotas:flag,
      updateYearQuotas:flag2
    })
  }
  componentDidMount() {
    // alert( this.props.cookies.get("njt_admin_userId_partner"))
    window.scrollTo(0, 0);
  }
  setLegalese = (event)=>{
    this.setState({
      legalese: event.target.value
    })

    //console.log("legalese change", event.target.value)
  }
  setOfferDescription = (event)=>{
    this.setState({
      offerDescription: event.target.value
    })

    //console.log("legalese change", event.target.value)
  }

  setModeTypeData = (data) => {
    if (data && data.length > 0)
      this.setState({
        modeTypeData: data,
        modeType: data[0].value,
      });
  };
  setIsCodeEnabled=(isCodeEnabled)=>{
    if (isCodeEnabled !== "2")
    {this.state.chooseAFile =''
    this.state.coupon_code_file_url=''
  }
    if(isCodeEnabled == "0")
    this.setState({
      isCodeEnabled:isCodeEnabled,
      discountCodeFlag: false,
      buttonShow:false,
    })
else if(isCodeEnabled == "1"){
this.setState({
  isCodeEnabled:isCodeEnabled,
  buttonShow:false
})
}
else this.setState({
  isCodeEnabled: isCodeEnabled,
  buttonShow:true
})

    // else 
    // this.setState({
    //   isCodeEnabled:isCodeEnabled
    // })
  }

  setCouponType = (type2) => {
    if(type2){
    let  type = JSON.parse(type2)
    // if (type == 2) {
      if(!type.coupon_transaction_subtype_id && type.value == coupon_transaction_type_id.prepaid && this.state.accountPayment.length==0)
      toast.info("Can’t create the Prepaid Offer because this business have not connected the Stripe Account.")
      else
      this.setState({
        coupon_transaction_type_id:type.coupon_transaction_subtype_id?coupon_transaction_type_id.discount: type.value,
        coupon_transaction_type:type2,
        IsCostDisabled: (!type.coupon_transaction_subtype_id &&  type.value == coupon_transaction_type_id.prepaid),
        costPoints:(!type.coupon_transaction_subtype_id  && type.value == coupon_transaction_type_id.prepaid)?"":this.state.costPoints,
        couponValueFlag: false,
        coupon_transaction_subtype_Flag:false,
        costPointsFlag:false,
        
        coupon_transaction_subtype_id:type.coupon_transaction_subtype_id?type.value:null, // (type.value == coupon_transaction_type_id.discount) ? coupon_transaction_subtype_id.PercentageType:'',
        // showSubTypes: (type == coupon_transaction_type_id.discount),
        subTypeIndex: (type == coupon_transaction_type_id.discount)? this.state.subTypeIndex:'',
        showURL: (type == coupon_transaction_type_id.discount) ? this.state.showURL: false,
        redemptionURLFlag: (type == coupon_transaction_type_id.discount) ? this.state.redemptionURLFlag: false,
        redemptionURL: (type == coupon_transaction_type_id.discount) ? this.state.redemptionURL : '',
        queryString: (type == coupon_transaction_type_id.discount) ? this.state.queryString : ''
      });
    }


  };


  setCouponSubType=(type, index)=>{

    this.setState({
      coupon_transaction_subtype_id:type,
      coupon_transaction_subtype_Flag:false,
      subTypeIndex: index
    })

  }


  getMaxCouponCreateLimit = () => {
    const data = {
      setCouponThresholdLimit: this.setCouponThresholdLimit,
    };
    Server.getMaxCouponCreateLimit(data);
  };

  setCouponThresholdLimit = (response) => {
    let threshold = 0;
    let maxCouponsLimit = 0
    let Display_Coupon_Tag = false
    let maxMediaLimit = this.state.maxMediaLimit
    if(response)
    {
      threshold = response.threshold
      maxCouponsLimit =  parseInt(response.data.global_value)
      Display_Coupon_Tag = JSON.parse(response.Display_Coupon_Tag)
      maxMediaLimit=parseInt(response.MediaUploadLimit)
    }
// alert(maxMediaLimit)
    this.setState({  // response.threshold, parseInt(response.data.global_value), JSON.parse(response.Display_Coupon_Tag)
      threshold: threshold,
      maxCouponsLimit: maxCouponsLimit,
      Display_Coupon_Tag: Display_Coupon_Tag,
      maxMediaLimit:maxMediaLimit
    })
  }

  yearHandler =async (e) => {
    
    var year = e.target.value
  
    var NewMonthData = [];
    var MaxMonthlyQuotaLimit = 0;
    var monthlyQuota = 0;

    if (year && Number.isInteger(parseInt(year))) {
     
      let EndDate = dateFormat(
        new Date(this.state.QuotasContractDetails[0].term_end_date),
        "mm"
      );
      var startDate = dateFormat(this.state.term_start_date, "mm");

      // this.state.selectedMonth = startDate
      if (this.state.startYear == this.state.endYear) {
       
        if(new Date(this.state.QuotasContractDetails[0].term_start_date) > new Date())
        {

          this.state.minimunDate = new Date(this.state.QuotasContractDetails[0].term_start_date)
          this.state.maximumStartDate = new Date(this.state.QuotasContractDetails[0].term_end_date)
        }else{
          this.state.minimunDate = new Date()
          this.state.maximumStartDate = new Date(this.state.QuotasContractDetails[0].term_end_date)
        }

        while (startDate <= EndDate) {
          NewMonthData.push(monthData1[startDate - 1]);
          startDate++;
        }
      } else if (this.state.endYear == year) {

        this.state.minimunDate = new Date(year+"-01-01")
        this.state.maximumStartDate =new Date(this.state.QuotasContractDetails[0].term_end_date)
        for (var i = 0; EndDate > 0 && i < 13; i++) {
          NewMonthData.push(monthData1[i]);
          EndDate--;
        }
      } else if (
        this.state.startYear == year &&
        this.state.startYear == new Date().getFullYear()
      ) {

        if(new Date(this.state.QuotasContractDetails[0].term_start_date) > new Date())
        {

          // alert(new Date(this.state.QuotasContractDetails[0].term_start_date))
          this.state.minimunDate = new Date(this.state.QuotasContractDetails[0].term_start_date)
          this.state.maximumStartDate = new Date(year+"-12-31")
        }else{
          this.state.minimunDate = new Date()
          this.state.maximumStartDate = new Date(year+"-12-31")
        }

        do {
          NewMonthData.push(monthData1[startDate - 1]);
          startDate++;
        } while (startDate < 13);
      } else {
        this.state.minimunDate = new Date(year+"-01-01")
        this.state.maximumStartDate = new Date(year+"-12-31")
        NewMonthData = Array.from(monthData1);
      }
      // alert(this.state.maximumStartDate)
      var monthlyQuotas = this.state.QuotasContractDetails.filter((item) => {
        return item.year == year;
      });

      // alert(monthlyQuotas.length)
      // if(monthlyQuotas.length == 0)
      // {monthlyQuotas.push( this.state.QuotasContractDetails[0])
      //   if(!this.state.updateActiveMode)
      //   this.state.yearFlag=true
      //   else
      //   this.state.yearFlag=false
      // }
      // else
    //  let Y_flag =this.state.yearData.find(elem=>( elem.name == year))
    //  alert(Y_flag)
      // alert(JSON.stringify(this.state.yearData))
      // if(Y_flag)
      if(parseInt(year) < parseInt(new Date().getFullYear()))
      {
        if(!this.state.updateActiveMode)
        this.state.yearFlag = true
      }
      else
      this.state.yearFlag = false
      // else
      // this.state.yearFlag = true

      var faceValueData = [];

      for (var i = 0; i < monthlyQuotas.length; i++) {
        //  console.warn()
        // monthlyQuota = monthlyQuota + monthlyQuotas[i].coupon_max_quota;
        faceValueData.push({
          name: monthlyQuotas[i].face_value,
          value: monthlyQuotas[i].face_value,
        });
      }

      // Sort Face Values in Ascending Order
      faceValueData = faceValueData.sort((a, b) => a.value - b.value)

      if (monthlyQuotas.length > 0)
        MaxMonthlyQuotaLimit = monthlyQuotas[0].coupon_lifetime_in_months;
      this.setMaxMonthlyQuotaLimit(MaxMonthlyQuotaLimit);
      this.state.year_id = monthlyQuotas[0].year_id;
      // if()
      // alert(JSON.stringify(NewMonthData))
      // if (NewMonthData[0].value != "")
      NewMonthData.unshift({ value: "", name: "Select Month" });
      faceValueData.unshift({
        value: "",
        name: "Select Offer Value / Discount Amount",
      });
      // alert(MaxMonthlyQuotaLimit)
    await  this.setState({
        MaxMonthlyQuota: monthlyQuota,
        MaxMonthlyQuotaLimit: MaxMonthlyQuotaLimit,
        faceValueData: faceValueData,
        year: e.target.value,
        month: "",
        // monthValues: NewMonthData,
        // faceValue: "",
        MonthlyQuota: "-",
        endDate: "",
        usedOnDate: "",
        startDate: "",
        canBeUsedStartDate: "",
        canBeUsedEndDate: "",
        Quantity: "",
        QuantityFlag: false,
        QuantityErrorMsg: "Field Required",
      });
    } else
     await this.setState({
        monthValues: NewMonthData,
        MaxMonthlyQuotaLimit: 0,
        faceValueData: [],
        year: e.target.value,
        month: "",
        // faceValue: "",
        MonthlyQuota: "-",
        MaxMonthlyQuota: 0,
        endDate: "",
        usedOnDate: "",
        startDate: "",
        canBeUsedStartDate: "",
        canBeUsedEndDate: "",
        Quantity: "",
        QuantityFlag: false,
        QuantityErrorMsg: "Field Required",
      });
    // if (
    //   this.state.year !== "" ||
    //   this.state.year !== "selectType"
    // ) {
    //   this.setState({ yearFlag: false });
    // }
    // this.setState({ year: e.target.value });
  };
  monthHandler = (e) => {
    var month = e.target.value;

    // alert(JSON.stringify(MonthValue))
    this.state.selectedMonth = month;
    // alert( MonthValue.value)
    // if (this.state.QuotasContractDetails.length > 0)
    //   this.props.monthDataHandler(month);
    // alert(this.state.year)
    this.startDateInit(this.state.year + "-" + month + "-" + "01");

    // alert(this.state.selectedMonth)
    this.setState({
      month,
      monthFlag: false,
      faceValue: "",
      MonthlyQuota: "-",
      MaxMonthlyQuota: 0,
      endDate: "",
      usedOnDate: "",
      canBeUsedStartDate: "",
      canBeUsedEndDate: "",
      Quantity: "",
      QuantityFlag: false,
      QuantityErrorMsg: "Field Required",
    });
    // if (
    //   this.state.month !== "" ||
    //   this.state.month !== "selectType"
    // ) {
    //   this.setState({ monthFlag: false });
    // }
    // this.setState({ month: e.target.value });
  };

  faceValueDataHandler = (e) => {
    var faceValue = e.target.value;

    if (this.state.year == "") this.setState({ yearFlag: true });
    // else if (this.state.month == "") this.setState({ monthFlag: true });
    else {
      //      this.props.faceValueDataHandler(faceValue);

      var monthlyQuotas = this.state.QuotasContractDetails.filter((item) => {
      
        return item.year == this.state.year && item.face_value == faceValue;
      });
// alert(JSON.stringify(monthlyQuotas))
      if (monthlyQuotas.length > 0) 
        {
            this.state.yearId = monthlyQuotas[0].year_id;
            this.state.MonthlyQuota = monthlyQuotas[0].coupon_max_quota
            this.state.MaxMonthlyQuota = monthlyQuotas[0].coupon_max_quota
        }else{
          this.state.MonthlyQuota = this.state.QuotasContractDetails[0].coupon_max_quota
          this.state.MaxMonthlyQuota = this.state.QuotasContractDetails[0].coupon_max_quota
        }
        this.setState({
          faceValue: faceValue,
          faceValueFlag: false,
          chooseAFile:"",
          Quantity: "",
          QuantityFlag: false,
          QuantityErrorMsg: "Field Required",
        });
      }
    
  };

  resetFaceValue=()=>{
    this.setState({
      faceValue: " ",
      faceValueFlag: false,
      // MonthlyQuota: 0,
      // MaxMonthlyQuota: 0,
      Quantity: "",
      QuantityFlag: false,
      QuantityErrorMsg: "Field Required",
    })
  }
  couponValueDataHandler = (e) => {
    var couponValue = e.target.value;
    this.setState({
      couponValue: couponValue,
      couponValueFlag: false,
    });
  };

  setYearsQuota = (QuotaYears, QuotasContractDetails, accountPayment=[]) => {
    // alert("genCouponHandler works")
    if (QuotasContractDetails.length > 0) {
        // alert(QuotasContractDetails[0])
      this.state.QuotasContractDetails = QuotasContractDetails;
      var date = new Date(QuotasContractDetails[0].term_start_date);
      if (date > this.state.term_start_date) this.state.term_start_date = date;
      if (QuotaYears.length > 0) this.state.startYear = QuotaYears[0].name;
     
      QuotaYears.unshift({ value: "Select Year", name: "Select Year" });
  
      this.state.endYear = new Date(
        QuotasContractDetails[0].term_end_date
      ).getFullYear();
      this.setState({
        yearData: QuotaYears, 
        accountPayment:accountPayment
      });
    }
  };

  setUpdateYearQuota=(QuotaYears, QuotasContractDetails)=>{
    // alert(QuotasContractDetails.length)
    if (QuotasContractDetails.length > 0) {

      if(!this.state.updateActiveMode)
      {
        let d= new Date()
        QuotaYears= QuotaYears.filter(item=>{
          // alert(parseInt(d.getFullYear()) +"  "+parseInt(item.value))
          return parseInt(item.value) >= parseInt(d.getFullYear())
        })
        QuotaYears.unshift({ value: "Select Year", name: "Select Year" });
  
        this.state.yearData = QuotaYears
        
      }
      var date = new Date(QuotasContractDetails[0].term_start_date);
      if (date > this.state.term_start_date) 
      this.state.term_start_date = date;
      if (QuotaYears.length > 0) this.state.startYear = QuotaYears[0].name;
      this.state.endYear = new Date(
        QuotasContractDetails[0].term_end_date
      ).getFullYear();
        this.setState({
          QuotasContractDetails:QuotasContractDetails
        },()=>{
          this.updateStatesAfterQuotas()
        })
    }
  }
  startDateInit = (startDate) => {
    //-- *************************************************** Sets the start date when user selects Contract year and month from GetQuotasContractDetailsContainer
    var date = new Date(); // **** current date
    if (new Date(startDate) > date) date = new Date(startDate);
    var maxDate = new Date(date);
    var maximumStartDate = new Date(date);
    maximumStartDate.setMonth(maximumStartDate.getMonth() + 1);

    maxDate.setMonth(maxDate.getMonth() + this.state.MaxMonthlyQuotaLimit);
    const usedMaximumDate = new Date(this.state.QuotasContractDetails[0].term_end_date);
    if (new Date(this.state.QuotasContractDetails[0].term_end_date) < maxDate)
      maxDate = this.state.QuotasContractDetails[0].term_end_date;

    maximumStartDate.setDate("01");
    maximumStartDate.setDate(maximumStartDate.getDate() - 1);
    // alert(maxDate)
    this.setState({
      startDate: date,
      maximumDate: new Date(maxDate),
      maximumStartDate: maximumStartDate,
      usedMaximumDate: usedMaximumDate,
      endDate: "",
      usedOnDate: "",
      canBeUsedStartDate: "",
      canBeUsedEndDate: "",
      minimunDate: new Date(date),
      //initialDate: new Date(date),
    });
  };
  setmaxUnusedQuota = (maxUnusedQuota) => {
    //-- *************************************************** Sets the max unused quota which is calculated from GetQuotasContractDetailsContainer
    this.setState({
      maxUnusedQuota:maxUnusedQuota
    })
    // this.state.maxUnusedQuota = maxUnusedQuota;
  };
  setMaxMonthlyQuotaLimit = (maxMonthlyLimit) => {
    //-- *************************************************** this function assings the

    this.state.MaxMonthlyQuotaLimit = maxMonthlyLimit;
  };

  handleShowCouponPreview = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });

  setMonday = () => {
    
    this.setState(
      {
        showMonday: !this.state.showMonday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setTuesday = () => {
    // alert("setTuesday works");
    this.setState(
      {
        showTuesday: !this.state.showTuesday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setWednesday = () => {
    // alert("setWednesday works");
    this.setState(
      {
        showWednesday: !this.state.showWednesday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setThursday = () => {
    // alert("setThursday works");
    this.setState(
      {
        showThursday: !this.state.showThursday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setFriday = () => {
    // alert("setFriday works");
    this.setState(
      {
        showFriday: !this.state.showFriday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setSaturday = () => {
    // alert("setSaturday works");
    this.setState(
      {
        showSaturday: !this.state.showSaturday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  setSunday = () => {
    // alert("setSunday works");
    this.setState(
      {
        showSunday: !this.state.showSunday,
      },
      () => {
        this.checkDaysUnselected();
      }
    );
  };
  // yearHandler = e => {
  //   if (
  //     this.state.year !== "" ||
  //     this.state.year !== "selectType"
  //   ) {
  //     this.setState({ yearFlag: false });
  //   }
  //   this.setState({ year: e.target.value });
  // };
  // monthHandler = e => {
  //   if (
  //     this.state.month !== "" ||
  //     this.state.month !== "selectType"
  //   ) {
  //     this.setState({ monthFlag: false });
  //   }
  //   this.setState({ month: e.target.value });
  // };
  modeHandler = (e) => {
    // alert(e.target.value)
    if (this.state.modeType !== "" || this.state.modeType !== "selectType") {
      this.setState({ modeTypeFlag: false });
    }
    // alert(e.target.value)
    this.setState({ modeType: e.target.value });
  };
  // descriptionHandler = (e) => {
  //   if (this.state.description !== "") {
  //     this.setState({ descriptionFlag: false });
  //   }
  //   this.setState({ description: e.target.value });
  // };
  // detailsHandler = (e) => {
  //   if (this.state.details !== "") {
  //     this.setState({ detailsFlag: false });
  //   }
  //   this.setState({ details: e.target.value });
  // };
  messageHandler = (e) => {
    if (this.state.message !== "") {
      this.setState({ messageFlag: false });
    }
    this.setState({ message: e.target.value });
  };

  setRetailerCouponTerm = (terms) => {
    // alert(terms)
    this.setState({
      CouponTerms: terms,
    });
  };

  amountValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ amountValueFlag: false });
    } else {
      this.setState({
        amountValueFlag: true,
        amountValueErrorMsg: "Only numeric values are allowed",
      });
    }
    this.setState({ amountValue: e.target.value });
  };
  // costPointsHandler = (e) => {
  //   const reg = /^\d*$/;
  //   const validReg = reg.test(e.target.value);
  //   if (validReg) {
  //     if (e.target.value < 1 && e.target.value != "") {
  //       this.setState({
  //         costPointsFlag: true,
  //         costPointsErrorMsg: "Cost in Points can not be less than 1",
  //       });
  //     } else this.setState({ costPointsFlag: false });
  //   } else {
  //     this.setState({
  //       costPointsFlag: true,
  //       costPointsErrorMsg: "Only numeric values are allowed",
  //     });
  //   }
  //   this.setState({ costPoints: e.target.value });
  // };
  costPointsHandler = (e) => {
    const reg = /^\d*$/;
    const validReg = reg.test(e.target.value);
    this.state.costPoints = e.target.value

    if (validReg) {
      this.setState({ costPointsFlag: false });
    } else {
      this.setState({
        costPointsFlag: true,
        costPointsErrorMsg: "Only numeric values are allowed",
      });
    }

  };
  setRetailerCouponImage = (image, e) => {
    let flag =true

      if(Array.isArray(image) && image.length>this.state.maxMediaLimit)
      flag = false
      if(flag)
      this.setState({
        CouponLogo: image,
        CouponImage: e,
        couponImageFlag: false
      });
    };
  
  offerHandler =(e)=>{
    const reg =  /^[a-zA-Z0-9_ ',%&.`$-]*$/;
    const validReg = reg.test(e.target.value);
    // this.state.offer_name = e.target.value
    // this.state.CouponTerms = e.target.value
    // if (validReg) {
    //   this.setState({ offer_flag: false, offer_error:"" });
    // } else {
    //   this.setState({
    //     offer_flag: true,
    //     offer_error:"Use only Alphabets, numeric , hyphen , dash, comma, ampersand, period, dollar, percentage and apostrophe",
    //   });
    // }
    this.setState({ offer_name: e.target.value, CouponTerms:e.target.value === ''?undefined:e.target.value, offer_flag: false, offer_error:"" });
  }

  // todo: XQuantity Handler
  xQuantityHandler = (e) => {
    const reg = /^[0-9]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.state.xquantityFlag = false
      if(e.target.value < 1)
      {
        this.state.xquantityFlag = true
        this.state.xquantityErrorMsg = "Should not be less than 1"
      }
    } else {
      this.state.xquantityFlag = true

      this.state.xquantityErrorMsg = "Use only Numbers"

    }
    this.setState({ xquantity: e.target.value });
  };
setEditType=()=>{
  this.setState({needEdit:false})
}
    // todo: YQuantity Handler
    yQuantityHandler = (e) => {
      const reg = /^[0-9]*$/;
      const validReg = reg.test(e.target.value);
      if (validReg) {
        this.state.yquantityFlag = false
        if(e.target.value < 1)
        {
          this.state.yquantityFlag = true
          this.state.yquantityErrorMsg = "Should not be less than 1"
        }
      } else {
        this.state.yquantityFlag = true
  
        this.state.yquantityErrorMsg = "Use only Numbers"
  
      }
      this.setState({ yquantity: e.target.value });
    };

  QuantityHandler = (e) => {
    const Quantity = e.target.value;
    const reg = /^\d*$/;
    const validReg = reg.test(Quantity);
    if (validReg) {
      // alert(this.state.maxUnusedQuota)
      if (Quantity > this.state.maxUnusedQuota) {
        // alert(Quantity)
        this.setState({
          // quantity,
          QuantityFlag: true,
          QuantityErrorMsg: "Offer quantity has exceeded unused quota",
          // quantityRequired: true,
        });
      } else if (Quantity < 1 && Quantity != "")
        this.setState({
          // quantity,
          QuantityFlag: true,
          QuantityErrorMsg: "offer quantity can not be less than 1",
          // quantityRequired: true,
        });
      else if (
        this.state.maxCouponsLimit != "" &&
        Quantity > this.state.maxCouponsLimit
      ) {
        this.setState({
          QuantityFlag: true,
          QuantityErrorMsg:
            "maximum of " +
            this.state.maxCouponsLimit +
            " offers can be generated at a time",
        });
      } else this.setState({ QuantityFlag: false });
    } else {
      this.setState({
        QuantityFlag: true,
        QuantityErrorMsg: "Only numeric values are allowed",
      });
    }
    this.setState({
      Quantity: e.target.value,
      // endDate:'',
      // startDate:''
    });
  };
  // todo: dateRangeRadioHandler onChange Function ...................
  dateRangeRadioHandler = () => {
    // this.checkDaysUnselected()
    this.setState({
      RangeCheck: true,
      SpecificDayCheck: false,
      usedOnDate: "",
      selectedDay:true,
      usedOnDateFlag: false,
    });
  };
  setEditCouponData = (coupon)=>{
    // this.clearStates()
    if(ref_status.ACTIVE === coupon.status_id)
    this.state.updateActiveMode = true
    else
    this.state.updateActiveMode = false
    // alert(JSON.stringify(coupon.status_id))
    console.log("hello", coupon)

    this.setState({
      EditCouponData:coupon,
      EditMode:true,
      year:coupon.year
    },()=>{
      this.checkEditData()
    })
  }
  // todo: specificDayRadioHandler onChange Function ...................
  specificDayRadioHandler = () => {
    // alert("specificDayRadioHandler work")
    this.setState({
      RangeCheck: false,
      SpecificDayCheck: true,
      selectedDay: false,
      showMonday: false,
      showTuesday: false,
      showWednesday: false,
      showThursday: false,
      showFriday: false,
      showSaturday: false,
      showSunday: false,
      canBeUsedStartDate: "",
      canBeUsedEndDate: "",
      canBeUsedStartDateFlag: false,
      canBeUsedEndDateFlag: false,
    });
  };

  // todo: Start Date onChange Function ...................

  startDateHandler = (e) => {
    this.setState({
      canBeUsedStartDate: e,
      canBeUsedStartDateFlag: false,
      canBeUsedEndDate: "",
      usedOnDate: "",
      canBeUsedStartTime:undefined,
      canBeUsedStartTimeWithoutOffset:undefined,
      canBeUsedEndTime:undefined,
      canBeUsedStartTimeWithoutOffset:undefined,
      monthFlag: false,
    });
  };

  // todo: End Date onChange Function ...................

  endDateHandler = (e) => {

    // let canBeUsedStartTime = this.state.canBeUsedStartTime
    // let canBeUsedEndTime = this.state.canBeUsedEndTime
    // let canBeUsedStartTimeWithoutOffset = this.state.canBeUsedStartTimeWithoutOffset

    // const promotion_start_date = new Date(this.state.startDate).getTime()
    // const promotion_end_date = new Date(e).getTime()

    // console.log("promotion_start_date", promotion_start_date)
    // console.log("promotion_start_date", promotion_end_date)
    // console.log("promotion_start_date", moment(new Date(canBeUsedEndTime)).format("h:mm A").toString())
    // console.log("promotion_start_date", moment(new Date(canBeUsedStartTime)).format("h:mm A").toString())


    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartEqualTime = moment(new Date(canBeUsedEndTime)).format("h:mm A").toString() == moment(new Date(canBeUsedStartTime)).format("h:mm A").toString()

    // if (isPromStartEndDateEqual && (isDate(new Date(canBeUsedStartTime)) && isDate(new Date(canBeUsedEndTime))) && isPromStartEqualTime) {
    //   canBeUsedStartTime = null
    //   canBeUsedStartTimeWithoutOffset = null
    // }

    // alert(e.toISOString())
// alert(new Date(moment(e).utc().format("YYYY-MM-DD")))
    this.setState({
      canBeUsedEndDate: e,
      canBeUsedEndDateFlag: false,
      usedOnDate: "",
      canBeUsedEndTime : undefined,
      canBeUsedEndTimeWithoutOffset:undefined
      // canBeUsedStartTimeWithoutOffset:null,
      // canBeUsedStartTimeWithoutOffset :canBeUsedStartTimeWithoutOffset
    });
  };



  // todo: setStartTime onChange Function ...................

  setStartTime = (e) => {
    if (e != "Invalid Date") {
      var endTime = this.state.endTime
      let startTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.startDate != "")
        startDate = moment(this.state.startDate).format("YYYY-MM-DD") + " "
      if (this.state.endDate != "")
        endDate = moment(this.state.endDate).format("YYYY-MM-DD") + " "

      if (endTime)
        if (new Date(startDate + moment(e).format('HH:mm')) >= new Date(endDate + moment(endTime).format('HH:mm')))
         { 
          endTime = undefined
          this.state.endTimeWithoutOffset=null
         }

      this.setState({
        startTime: null
      },()=>{
        this.setState({
          startTime: e,
          startTimeFlag: false,
          endTime: endTime,
          startTimeWithoutOffset:startTimeWithoutOffset
        })
      })
    } else
      this.setState({
        startTimeFlag: true
      })
    // console.log("time:", time)
    // console.log("time", new Date())
    // console.log("timeses", moment(new Date(time)).format("h:mm A"))

    // var endTime = this.state.endTime

    // let startTimeWithoutOffset = time ? moment(new Date(time)).format("h:mm A"): time


    // // console.log("end date", moment(new Date(time)).format("HH:mm").toString())
    // // console.log("end date", moment(new Date(endTime)).format("HH:mm").toString())


    // const promotion_start_date = !isNaN(new Date(this.state.startDate)) ? new Date(this.state.startDate).setHours(0,0,0,0):new Date(this.state.startDate)
    // console.log("promotion_start_date", promotion_start_date)

    // // alert(isNaN(new Date(this.state.endDate).getTime()))
    // const promotion_end_date = !isNaN(new Date(this.state.endDate)) ? new Date(this.state.endDate).setHours(0,0,0,0):new Date(this.state.endDate)


    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartLessThanEndDate = moment(new Date(endTime)).format("h:mm A").toString() <= moment(new Date(time)).format("h:mm A").toString()

    // if (isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(new Date(endTime))) && isPromStartLessThanEndDate) {
    //   endTime = null
    //   startTimeWithoutOffset = null
    // }

    // console.log("invalid", time == "Invalid Date")

    // this.setState({
    //   startTime: time,
    //   startTimeWithoutOffset: startTimeWithoutOffset,
    //   endTime: endTime,
    //   startTimeFlag: time == "Invalid Date"
    // })


  }


  setCanBeUsedStartTime = (e)=>{
    
    if (e != "Invalid Date") {
      var endTime = this.state.canBeUsedEndTime
      let canBeUsedStartTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.canBeUsedStartDate != "")
        startDate = moment(this.state.canBeUsedStartDate).format("YYYY-MM-DD") + " "
      if (this.state.canBeUsedEndDate != "")
        endDate = moment(this.state.canBeUsedEndDate).format("YYYY-MM-DD") + " "

      if (endTime)
        if (new Date(startDate + moment(e).format('HH:mm')) >= new Date(endDate + moment(endTime).format('HH:mm')))
         { 
          endTime = undefined
          this.state.canBeUsedEndTimeWithoutOffset=null
         }

      this.setState({
        canBeUsedStartTime: null
      },()=>{
        // alert(e)
        this.setState({
          canBeUsedStartTime: e,
          canBeUsedStartTimeFlag: false,
          canBeUsedEndTime: endTime,
          canBeUsedStartTimeWithoutOffset:canBeUsedStartTimeWithoutOffset
        })
      })
    } else
      this.setState({
        canBeUsedStartTimeFlag: true
      })
    // console.log("time: ", time)
    // console.log("time", new Date())
    // console.log("timeses", moment(new Date(time)).format("h:mm A"))

    // var canBeUsedEndTime = this.state.canBeUsedEndTime
  
    // let canBeUsedStartTimeWithoutOffset = time ? moment(new Date(time)).format("h:mm A"): time


    // // console.log("end date", moment(new Date(time)).format("HH:mm").toString())
    // // console.log("end date", moment(new Date(canBeUsedEndTime)).format("HH:mm").toString())
    // const canBeUsedStartDate = !isNaN(new Date(this.state.canBeUsedStartDate)) ? new Date(this.state.canBeUsedStartDate).setHours(0,0,0,0):new Date(this.state.canBeUsedStartDate)
    // console.log("promotion_start_date", canBeUsedStartDate)

    // // alert(isNaN(new Date(this.state.endDate).getTime()))
    // const canBeUsedEndDate = !isNaN(new Date(this.state.canBeUsedEndDate)) ? new Date(this.state.canBeUsedEndDate).setHours(0,0,0,0):new Date(this.state.canBeUsedEndDate)



    // // const canBeUsedStartDate = new Date(this.state.canBeUsedStartDate).getTime()
    // // const canBeUsedEndDate = new Date(this.state.canBeUsedEndDate).getTime()

    // const isPromStartEndDateEqual = canBeUsedStartDate === canBeUsedEndDate
    // const isPromStartLessThanEndDate = moment(new Date(canBeUsedEndTime)).format("h:mm A").toString() <= moment(new Date(time)).format("h:mm A").toString()

    // if(isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(new Date(canBeUsedEndTime))) && isPromStartLessThanEndDate){
    //   canBeUsedEndTime = null
    //   canBeUsedStartTimeWithoutOffset = null
    // }

    // console.log("invalid time", time == "Invalid Date")

    // this.setState({
    //   canBeUsedStartTime:time,
    //   canBeUsedStartTimeWithoutOffset: canBeUsedStartTimeWithoutOffset,
    //   canBeUsedEndTime:canBeUsedEndTime,
    //   canBeUsedStartTimeFlag: time == "Invalid Date"
    // })


  }


  setCanBeUsedEndTime = (e)=>{
    if (e != "Invalid Date") {
      var startTime = this.state.canBeUsedStartTime
      let canBeUsedEndTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.canBeUsedStartDate != "")
        startDate = moment(this.state.canBeUsedStartDate).format("YYYY-MM-DD") + " "
      if (this.state.canBeUsedEndDate != "")
        endDate = moment(this.state.canBeUsedEndDate).format("YYYY-MM-DD") + " "

      if (startTime)
        if (new Date(startDate + moment(startTime).format('HH:mm')) >= new Date(endDate + moment(e).format('HH:mm')))
         { 
           startTime = undefined
           this.state.canBeUsedStartTimeWithoutOffset=undefined
         }
         this.setState({
          canBeUsedEndTime:undefined
         },()=>
      this.setState({
        canBeUsedStartTime: startTime,
        canBeUsedEndTimeFlag: false,
        canBeUsedEndTime: e,
        canBeUsedEndTimeWithoutOffset:canBeUsedEndTimeWithoutOffset
      }))
    } else
      this.setState({
        canBeUsedEndTimeFlag: true
      })
    // let canBeUsedStartTime = this.state.canBeUsedStartTime ? new Date(this.state.canBeUsedStartTime):this.state.canBeUsedStartTime
    // let canBeUsedEndTimeWithoutOffset = time ? moment(new Date(time)).format("h:mm A"): time

    // console.log("timeses mamma", moment(new Date(time)).format("h:mm A"))

    // // if(isDate(new Date(time)) && canBeUsedStartTime && new Date(time) < new Date(canBeUsedStartTime) && time)
    // // {
    // //   alert("hello")
    // //   canBeUsedStartTime = null
    // //   canBeUsedEndTimeWithoutOffset = null
    // // }

    // const canBeUsedStartDate = !isNaN(new Date(this.state.canBeUsedStartDate)) ? new Date(this.state.canBeUsedStartDate).setHours(0,0,0,0):new Date(this.state.canBeUsedStartDate)
    // console.log("promotion_start_date", canBeUsedStartDate)

    // // alert(isNaN(new Date(this.state.endDate).getTime()))
    // const canBeUsedEndDate = !isNaN(new Date(this.state.canBeUsedEndDate)) ? new Date(this.state.canBeUsedEndDate).setHours(0,0,0,0):new Date(this.state.canBeUsedEndDate)

    // // console.log("end date", promotion_start_date)
    // // console.log("end date", promotion_end_date)

    // console.log("end date", isDate(new Date(time)))
    // console.log("end date", isDate(new Date(canBeUsedStartTime)))

    // console.log("end date", moment(new Date(time)).format("h:mm A").toString())
    // console.log("end date", moment(new Date(canBeUsedStartTime)).format("h:mm A").toString())

    // const isPromStartEndDateEqual = canBeUsedStartDate === canBeUsedEndDate
    // const isPromStartGreaterThanEndDate = moment(new Date(time)).format("h:mm A").toString() <= moment(canBeUsedStartTime).format("h:mm A").toString()


    // if(isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(canBeUsedStartTime)) && isPromStartGreaterThanEndDate){
    //   canBeUsedStartTime = null
    //   canBeUsedEndTimeWithoutOffset = null
    // }


    // this.setState({
    //   canBeUsedEndTime:time,
    //   canBeUsedEndTimeWithoutOffset: canBeUsedEndTimeWithoutOffset,
    //   canBeUsedStartTime: canBeUsedStartTime,
    //   canBeUsedEndTimeFlag: time == "Invalid Date",
    // })

  }


  setEndTime = (e)=>{
    // alert(e)

    if (e != "Invalid Date") {
      var startTime = this.state.startTime
      let endTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.startDate != "")
        startDate = moment(this.state.startDate).format("YYYY-MM-DD") + " "
      if (this.state.endDate != "")
        endDate = moment(this.state.endDate).format("YYYY-MM-DD") + " "

      if (startTime)
        if (new Date(startDate + moment(startTime).format('HH:mm')) >= new Date(endDate + moment(e).format('HH:mm')))
         { 
           startTime = undefined
           this.state.startTimeWithoutOffset = null
         }
         this.setState({
          endTime:undefined
         },()=>
      this.setState({
        startTime: startTime,
        endTimeFlag: false,
        endTime: e,
        endTimeWithoutOffset:endTimeWithoutOffset
      }))
    } else
      this.setState({
        endTimeFlag: true
      })

    // console.log("time5454", time)

    // let startTime = this.state.startTime ? new Date(this.state.startTime): this.state.startTime
    // let endTimeWithoutOffset = time ? moment(new Date(time)).format("h:mm A"): time

    // console.log("timeses mamma", moment(new Date(time)).format("h:mm A"))

    // // if(isDate(new Date(time)) && startTime && new Date(time) < new Date(startTime) && time)
    // // {
    // //   startTime = null
    // //   endTimeWithoutOffset = null
    // // }

    // const promotion_start_date = !isNaN(new Date(this.state.startDate)) ? new Date(this.state.startDate).setHours(0,0,0,0):new Date(this.state.startDate)
    // console.log("promotion_start_date", promotion_start_date)

    // // alert(isNaN(new Date(this.state.endDate).getTime()))
    // const promotion_end_date = !isNaN(new Date(this.state.endDate)) ? new Date(this.state.endDate).setHours(0,0,0,0):new Date(this.state.endDate)


    // console.log("end date", promotion_start_date)
    // console.log("end date", promotion_end_date)

    // console.log("end date", isDate(new Date(time)))
    // console.log("end date", isDate(new Date(startTime)))

    // console.log("end date", moment(new Date(time)).format("HH:mm").toString())
    // console.log("end date", moment(new Date(startTime)).format("HH:mm").toString())

    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartGreaterThanEndDate = moment(new Date(time)).format("h:mm A").toString() <= moment(startTime).format("h:mm A").toString()


    // if(isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(startTime)) && isPromStartGreaterThanEndDate){
    //   startTime = null
    //   endTimeWithoutOffset = null
    // }


    // this.setState({
    //   endTime:time,
    //   endTimeWithoutOffset:endTimeWithoutOffset,
    //   startTime:startTime,
    //   endTimeFlag: time == "Invalid Date",
    // })

  }


  // todo: Start Date onChange Function ...................

  promotionStartDateHandler = (e) => {
    var maximumDate = new Date(e);
    maximumDate.setMonth(
      maximumDate.getMonth() + this.state.MaxMonthlyQuotaLimit
    );
    const usedMaximumDate = new Date(this.state.QuotasContractDetails[0].term_end_date);
    if (
      new Date(this.state.QuotasContractDetails[0].term_end_date) < maximumDate
    )
      maximumDate = new Date(this.state.QuotasContractDetails[0].term_end_date);
      // alert(maximumDate)
    this.setState({
      month: (new Date(e).getMonth())+1,
      monthFlag: false,
      faceValue: "",
      MonthlyQuota: "-",
      MaxMonthlyQuota: 0,
      MonthlyQuota :"-",
      endDate: "",
      usedOnDate: "",
      canBeUsedStartDate: "",
      canBeUsedEndDate: "",
      Quantity: "",
      QuantityFlag: false,
      QuantityErrorMsg: "Field Required",
      startDateFlag: false,
      startDate: e,
      endDate: "",
      usedOnDate: "",
      canBeUsedEndDate: "",
      canBeUsedStartDate: "",
      maximumDate: new Date(maximumDate),
      usedMaximumDate: usedMaximumDate,
      // coupon_transaction_type:'',
      startTime:undefined,
      endTime:undefined,
      canBeUsedStartTime:undefined,
      canBeUsedEndTime:undefined,
      endTimeWithoutOffset:null,
      startTimeWithoutOffset: null,
      canBeUsedEndTimeWithoutOffset:null,
      canBeUsedStartTimeWithoutOffset:null,

    });

    
    // this.setState({ startDate: e, startDateFlag: false, endDate: "" });

    // this.setState({ promotionStartDate: e, promotionStartDateFlag: false, promotionEndDate: "" });
  };

  // todo: End Date onChange Function ...................

  promotionEndDateHandler = (e) => {
    
    let startTime = this.state.startTime
    // let endTime = this.state.endTime
    // let startTimeWithoutOffset = this.state.startTimeWithoutOffset

    // const promotion_start_date = new Date(this.state.startDate).getTime()
    // const promotion_end_date = new Date(e).getTime()

    // console.log("promotion_start_date", promotion_start_date)
    // console.log("promotion_start_date", promotion_end_date)
    // console.log("promotion_start_date", startTime)
    // console.log("promotion_start_date", endTime)


    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartLessThanEndTime = moment(new Date(endTime)).format("HH:mm").toString() == moment(new Date(startTime)).format("HH:mm").toString()

    // if (isPromStartEndDateEqual && (isDate(new Date(startTime)) && isDate(new Date(endTime))) && isPromStartLessThanEndTime) {
    //   startTime = null
    //   startTimeWithoutOffset = null
    // }

// alert(e)
    this.setState({
      endDate: e,
      endDateFlag: false,
      usedOnDate: "",
      canBeUsedEndDate: "",
      canBeUsedStartDate: "",
      startTime: startTime,
      endTime:undefined,
      endTimeWithoutOffset:null,
      canBeUsedStartTime:undefined,
      canBeUsedEndTime:undefined,
      canBeUsedEndTimeWithoutOffset:null,
      canBeUsedStartTimeWithoutOffset:null
    });
  };

  // todo: start Date onChange Function ...................

  usedOnDateHandler = (e) => {
    
    this.setState({ usedOnDate: e, usedOnDateFlag: false });
  };

  // todo: applyHandler onClick Function ...................
  backHandler = () => {
    // alert("backHandler work")
    this.props.history.replace({
      pathname: "/busines_coupon_quotas",
    });
  };

  scrollToCouponYear = () => {
    this.CouponYear.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToUsedStarting = () => {
    this.UsedStarting.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToMessage = () => {
    this.UsedMessage.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToCouponQuantity = () => {
    this.CouponQuantity.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  scrollToXYQuantity = () => {
    this.xquantity.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  scrollToPromotionStartDate = () => {
    this.StartDatePromotion.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  scrollToOfferValue = () => {
    this.OfferValueScroll.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  scrollToStartDate = () => {
    this.StartDate.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToEndDate = () => {
    this.EndDate.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToCouponType = () => {
    this.CouponType.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToCouponOffer =() => {
    this.nameCouponOffer.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  scrollToReferralFees = () => {
    this.ReferralFees.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  // setSubTypeData=(subTypes)=>{
  //   if(this.state.SubTypes.length === 0)
  //   this.setState({
  //     SubTypes:subTypes
  //   })
  // }
  

  applyHandler = (saveAsDraft = false) => {
    if (this.ValidateFields()) {
      let StartDate = new Date(this.state.startDate)


      let endDate = new Date(this.state.endDate)

      let canBeUsedEndTime= this.state.canBeUsedEndTime
      var m = moment();
            m.set({hour:23,minute:59,second:59,millisecond:0})

      canBeUsedEndTime=canBeUsedEndTime?moment_tz(canBeUsedEndTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')
      let canBeUsedStartTime= this.state.canBeUsedStartTime
     
            m.set({hour:0,minute:0,second:0,millisecond:0})
      canBeUsedStartTime=canBeUsedStartTime?moment_tz(canBeUsedStartTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')
      // alert(canBeUsedStartTime)

      var CanBeUsedData = {};

      var redemption_url=null;
      redemption_url = this.state.redemptionURL.trim()
        // if (redemption_url!="" && redemption_url.slice(-1) != "/")
        //   redemption_url = redemption_url + "/"
        if (this.state.showQueryString)
          redemption_url = redemption_url + "?" + this.state.queryString.replace("?","")

      if (this.state.RangeCheck) {
        var flag = false;
        if (this.state.canBeUsedStartDate === "") {
          this.setState({
            canBeUsedStartDateErrorMsg: "Field Required",
            canBeUsedStartDateFlag: true,
          });
          this.scrollToCouponYear();
        } else if (this.state.canBeUsedEndDate === "") {
          this.setState({
            canBeUsedEndDaterMsg: "Field Required",
            canBeUsedEndDateFlag: true,
          });
          this.scrollToCouponYear();
        } 
        // else if (this.state.selectedDay && this.checkDaysUnselected()) {
          // CanBeUsedData = {
          //   redemption_start_date: dateFormat(
          //     this.state.canBeUsedStartDate,
          //     "yyyy-mm-dd"
          //   ),
          //   redemption_end_date: dateFormat(
          //     this.state.canBeUsedEndDate,
          //     "yyyy-mm-dd"
          //   ),
          //   can_only_be_used_on: null,
          // };
          // flag = true;
        // } 
        else {
          
let canBeUsedEndDate = new Date(this.state.canBeUsedEndDate)
// canBeUsedEndDate.setHours(23,0,0,0)
// canBeUsedEndDate = canBeUsedEndDate.toISOString().substring(0,10)
let canBeUsedStartDate = new Date(this.state.canBeUsedStartDate)
// canBeUsedStartDate.setHours(23,0,0,0)
// canBeUsedStartDate = canBeUsedStartDate.toISOString().substring(0,10)



        //   let offSet = new Date().getTimezoneOffset()
        // offSet = offSet / 60
  
        // let symbol = "-"
        // if (offSet < 0) {
        //   symbol = "+"
        //   offSet = Math.abs(offSet)
        // }
        // if (offSet < 10)
        //   offSet = "0" + offSet
  
        // console.log("start date", this.state.startDate + "T" + this.state.startTimeWithoutOffset + symbol + offSet +":00")
  
        // console.log("start date", this.state.canBeUsedStartTimeWithoutOffset)
        // console.log("start date", this.state.canBeUsedEndTimeWithoutOffset)
        // console.log("start date", symbol)
        // console.log("start date", offSet)
        // alert(moment(this.state.canBeUsedEndTimeWithoutOffset).format("HH:mm"))
        // "h:mm A").format("HH:mm:ss")
        // let canBeUsedEndTime= this.state.canBeUsedEndTime
        // canBeUsedEndTime=canBeUsedEndTime?moment_tz(canBeUsedEndTime).utc().format('HH:mm:ss'):moment_tz(moment(new Date()).format("YYYY-MM-DD") +" 23:59:59").utc().format('HH:mm:ss')
        // let canBeUsedStartDate= this.state.canBeUsedStartDate
        // canBeUsedStartDate=canBeUsedStartDate?moment_tz(canBeUsedStartDate).utc().format('HH:mm:ss'):moment_tz(moment(new Date()).format("YYYY-MM-DD") +" 00:00:00").utc().format('HH:mm:ss')
        // alert(canBeUsedStartDate)
        // const canBeUsedStartTimeWithoutOffset = this.state.canBeUsedStartTimeWithoutOffset ? moment(this.state.canBeUsedStartTimeWithoutOffset, "h:mm A").format("HH:mm:ss") : "00:00:00"
        // const canBeUsedEndTimeWithoutOffset = this.state.canBeUsedEndTimeWithoutOffset ? moment(this.state.canBeUsedEndTimeWithoutOffset, "h:mm A").format("HH:mm:ss") : "23:59:59"
     
        // console.log("start date", new Date(moment(this.state.startDate).format("YYYY-MM-DD") + "T" + canBeUsedStartTimeWithoutOffset + symbol + offSet +":00"))
        // console.log("start date", new Date(moment(this.state.endDate).format("YYYY-MM-DD") + "T" + canBeUsedEndTimeWithoutOffset + symbol + offSet +":00"))

          // alert(canBeUsedEndDate)
          CanBeUsedData = {
            redemption_start_date:moment_tz(moment(canBeUsedStartDate).utcOffset(0, true).format("YYYY-MM-DD") + " " + canBeUsedStartTime).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
            redemption_end_date:moment_tz(moment(canBeUsedEndDate).utcOffset(0, true).format("YYYY-MM-DD")  + " " + canBeUsedEndTime).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
            can_only_be_used_on: null,
          };
          flag = true;
          // this.scrollToCouponQuantity();
        }
      } else {
        if (this.state.usedOnDate === "") {
          this.setState({
            usedOnDateErrorMsg: "Field Required",
            usedOnDateFlag: true,
          });
          this.scrollToCouponQuantity();
        } else {
          flag = true;
          CanBeUsedData = {
            can_only_be_used_on:moment_tz(this.state.usedOnDate).utcOffset(0, true).format("YYYY-MM-DD"),
            redemption_start_date: null,
            redemption_end_date: null,
          };
        }
      }
      if (flag) {
        // alert(this.state.selectedDay)
        // OverrideData = { ...OverrideData, ...CanBeUsedData };
        // const d = new Date();

        // let offSet = d.getTimezoneOffset()
        // offSet = offSet / 60
  
        // let symbol = "-"
        // if (offSet < 0) {
        //   symbol = "+"
        //   offSet = Math.abs(offSet)
        // }
        // if (offSet < 10)
        //   offSet = "0" + offSet
  
        // // console.log("start date", this.state.startDate + "T" + this.state.startTimeWithoutOffset + symbol + offSet +":00")
  
        // console.log("start date", this.state.startTimeWithoutOffset)
        // console.log("start date", this.state.endTimeWithoutOffset)
        // // console.log("start date", symbol)
        // // moment(new Date(startTimeWithoutOffset)).format("HH:mm:ss")
        // // moment(new Date(endTimeWithoutOffset)).format("HH:mm:ss")
        // // console.log("start date", offSet)
  
        // const startTimeWithoutOffset = this.state.startTimeWithoutOffset ? moment(this.state.startTimeWithoutOffset, "h:mm A").format("HH:mm:ss") : "00:00:00"
        // const endTimeWithoutOffset = this.state.endTimeWithoutOffset ? moment(this.state.endTimeWithoutOffset, "h:mm A").format("HH:mm:ss") : "23:59:59"
  
        // // alert(moment(this.state.startTimeWithoutOffset, "h:mm A").format("HH:mm:ss"))

        // console.log("start date", new Date(moment(this.state.startDate).format("YYYY-MM-DD") + "T" + startTimeWithoutOffset + symbol + offSet +":00"))
        // console.log("start date", new Date(moment(this.state.endDate).format("YYYY-MM-DD") + "T" + endTimeWithoutOffset + symbol + offSet +":00"))

        m.set({hour:23,minute:59,second:59,millisecond:0})

        let endTime = this.state.endTime
        
        endTime=endTime?moment_tz(endTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')
        m.set({hour:0,minute:0,second:0,millisecond:0})
        let startTime= this.state.startTime
        startTime=startTime?moment_tz(startTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')
        // alert(JSON.stringify(CanBeUsedData))
        let CouponData = {
          // promotion_short_description: this.state.description,
          cost_in_points: this.state.costPoints,
          redemption_message: this.state.message,
          valid_monday: this.state.selectedDay?this.state.selectedDay: this.state.showMonday,
          valid_tuesday: this.state.selectedDay?this.state.selectedDay: this.state.showTuesday,
          valid_wednesday: this.state.selectedDay?this.state.selectedDay: this.state.showWednesday,
          valid_thursday:this.state.selectedDay?this.state.selectedDay:  this.state.showThursday,
          valid_friday: this.state.selectedDay?this.state.selectedDay: this.state.showFriday,
          valid_saturday: this.state.selectedDay?this.state.selectedDay: this.state.showSaturday,
          valid_sunday: this.state.selectedDay?this.state.selectedDay: this.state.showSunday,
          coupon_face_value: this.state.faceValue,
          offer_name:this.state.offer_name,
          application_user_id:
            this.props.cookies.get("njt_admin_userId_partner") &&
            this.props.cookies.get("njt_admin_userId_partner").application_user_id
              ? this.props.cookies.get("njt_admin_userId_partner").application_user_id
              : 1,
          coupon_create_dttm: moment_tz(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",//moment(d).format("YYYY-MM-DD hh:mm:ss"),
            // dateFormat(d, "yyyy-mm-dd") +
            // " " +
            // d.getHours() +
            // ":" +
            // d.getMinutes() +
            // ":" +
            // d.getSeconds(),
          promotion_start_date:moment_tz(moment(StartDate).utcOffset(0, true).format("YYYY-MM-DD")  + " " +startTime).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00", //dateFormat(this.state.startDate, "yyyy-mm-dd"),
          promotion_end_date:moment_tz(moment(endDate).utcOffset(0, true).format("YYYY-MM-DD")   + " " +endTime).utc().format('YYYY-MM-DD HH:mm:ss') +"+00:00", //dateFormat(this.state.endDate, "yyyy-mm-dd"),
          business_id: this.props.cookies.get("BusinessProfileData").business_id,
          month_id: this.state.month,
          coupon_quota: this.state.Quantity,
          year_id: this.state.yearId,
          coupon_terms:
            this.state.CouponTerms == ""
              ? null
              : this.capitalize(this.state.CouponTerms),
          override_coupon_pricing: true,
          image_location:
            this.state.CouponLogo == "" ? null : this.state.CouponLogo,
          coupon_transaction_type_id: this.state.coupon_transaction_type_id,
          coupon_transaction_subtype_id: this.state.coupon_transaction_subtype_id == 0 ? null: this.state.coupon_transaction_subtype_id,
          legalese: this.state.legalese = "" ? null: this.state.legalese,
          generated_by:this.props.cookies.get("njt_admin_userId_partner") &&
          this.props.cookies.get("njt_admin_userId_partner").user_name
            ? this.props.cookies.get("njt_admin_userId_partner").user_name
            : "admin",
            coupon_code: parseInt(this.state.isCodeEnabled)? this.state.coupon_code.trim() : null,
            show_code_in_redemption_url: this.state.discountCodeCheckBox,
            redemption_url: redemption_url !="/"?redemption_url:null,
            buy_x_quantity:this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?this.state.xquantity:null,
            get_y_quantity:this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?this.state.yquantity:null,
        file_img_name:  this.state.coupon_transaction_type_id !=""&&  this.state.isCodeEnabled == "2" && this.state.chooseAFile !=""?this.state.chooseAFile:null,
        coupon_code_file_url: this.state.coupon_transaction_type_id !="" &&  this.state.isCodeEnabled == "2" && this.state.coupon_code_file_url ?this.state.coupon_code_file_url:null,
        business_name:this.props.cookies.get("BusinessProfileData").business_name,
        featured_offer_order: this.state.featuredOfferOrder ? parseInt(this.state.featuredOfferOrder) : null,
        wallet_page_offer_url:this.state.walletURL.trim() !=="" ?this.state.walletURL:null,
        rewards_page_offer_url:this.state.rewardURL.trim() !=="" ?this.state.rewardURL:null ,
        offer_description:this.state.offerDescription.trim() !=="" ? this.state.offerDescription:null
      };
      //  alert(JSON.stringify(CouponData))
        if (!this.state.isLoading) {
          if(CouponData.image_location && !Array.isArray(CouponData.image_location))
          CouponData.image_location = [CouponData.image_location]
        if(CouponData.image_location)  
        {
          CouponData.media_location =  CouponData.image_location
          try{CouponData.image_location =  JSON.parse(CouponData.media_location[0]).image_location
          }catch(e){
            CouponData.image_location = null
            CouponData.media_location = null
          }
        }
          this.state.isLoading = true;
          if(saveAsDraft){
            if(this.state.EditCouponData)
            this.props.updateDraft({
              ...CouponData,
              ...CanBeUsedData,
              coupon_batch_id:this.state.EditCouponData.coupon_batch_id
            });
          else
            this.props.createDraft({
            ...CouponData,
            ...CanBeUsedData
          });
          }else
          {
            if(this.state.updateActiveMode){
              this.props.updateExpiryDate({
                promotion_end_date:CouponData.promotion_end_date,
              ...CanBeUsedData,
              valid_monday: this.state.selectedDay?this.state.selectedDay: this.state.showMonday,
              valid_tuesday: this.state.selectedDay?this.state.selectedDay: this.state.showTuesday,
              valid_wednesday: this.state.selectedDay?this.state.selectedDay: this.state.showWednesday,
              valid_thursday:this.state.selectedDay?this.state.selectedDay:  this.state.showThursday,
              valid_friday: this.state.selectedDay?this.state.selectedDay: this.state.showFriday,
              valid_saturday: this.state.selectedDay?this.state.selectedDay: this.state.showSaturday,
              valid_sunday: this.state.selectedDay?this.state.selectedDay: this.state.showSunday,
              coupon_batch_id:this.state.EditCouponData.coupon_batch_id,
              featured_offer_order: this.state.featuredOfferOrder ? parseInt(this.state.featuredOfferOrder) : null,
              legalese:CouponData.legalese,
              coupon_terms:CouponData.coupon_terms,
              coupon_term:CouponData.coupon_terms,
              offer_name:CouponData.offer_name,
              image_location:CouponData.image_location,
              media_location:CouponData.media_location,
              offer_description:CouponData.offer_description
            })
                
            }
            else if(this.state.EditCouponData){
          
              this.props.GenerateCouponsFromDraft({
                ...CouponData,
                ...CanBeUsedData,
                coupon_batch_id:this.state.EditCouponData.coupon_batch_id
              })
            }else
            this.props.GenerateOverridePriceCoupons({
            ...CouponData,
            ...CanBeUsedData,
          })
        }
        }
      }
    }
  };

  nextHandler = () => {
    this.props.history.replace({
      pathname: "/business_list",
    });
  };

  setDiscountCode = (e)=>{
    const reg = /^[a-zA-Z0-9-,]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.state.discountCodeFlag = false
      // this.setState({ discountCodeFlag: false });
    } else {
      this.state.discountCodeFlag = true
      this.state.discountCodeErrorMsg = "Use only Alphabets, Numeric , Hyphen and Comma"
      // this.setState({
      //   discountCodeFlag: true,
      //   discountCodeErrorMsg:"Use only Alphabets, Numeric , Hyphen and Comma",
      // });
    }
    this.setState({
      coupon_code: e.target.value
    })
  }

// todo: Start percentageRadioHandler ...................

percentageRadioHandler = () => {
  // alert("percentageRadioHandler work");
}

// todo: Start dollarRadioHandler ...................
dollarRadioHandler = () => {
  // alert("dollarRadioHandler work");
}

  // todo: Coupon Preview onClick Function ...................

  ValidateFields =  (isPreview = false) => {

    var valid = false;

    console.log("feature", this.state.startTime)
    console.log("feature", this.state.startTimeFlag)
    // console.log("feature", this.state.featuredOfferOrder)

    var isPrepaid = this.state.coupon_transaction_type_id == 2;
    if (isPrepaid) {
      isPrepaid = false;
      this.state.costPoints = null;
    } else
      isPrepaid = this.state.costPointsFlag || this.state.costPoints === "";

      if (this.state.offer_flag || this.state.offer_name === "") {
        this.setState({
          offer_error: this.state.offer_error.length>0?this.state.offer_error:"Field Required",
          offer_flag: true,
        });
        this.scrollToCouponOffer()
      } 

    else if (this.state.year === "Select Year" || this.state.year === "" || this.state.yearFlag) {
      this.setState({
        yearErrorMsg: "Field Required",
        yearFlag: true,
      });
      this.scrollToCouponYear();
    } 
    // else if (this.state.month === "selectType" || this.state.month === "") {
    //   this.setState({
    //     monthErrorMsg: "Field Required",
    //     monthFlag: true,
    //   });
    //   this.scrollToCouponYear();
    // }

    else if (this.state.startDate === "") {
      this.setState({
        startDateErrorMsg: "Field Required",
        startDateFlag: true,
      });
      this.scrollToPromotionStartDate();
    }

    else if ((!this.state.startTime || this.state.startTime == "Invalid Date") && this.state.startTimeFlag) {
        this.scrollToCouponYear(); 
        return;
    }
    else if (this.state.endDate === "") {
      this.setState({
        endDaterMsg: "Field Required",
        endDateFlag: true,
      });
      this.scrollToPromotionStartDate();
    }
    else if ((!this.state.endTime || this.state.endTime == "Invalid Date") && this.state.endTimeFlag) { 
        this.scrollToCouponYear(); 
        return;
    }
    else if (
      this.state.coupon_transaction_type_id === "0" ||
      this.state.coupon_transaction_type_id === ""
    ) {
      //alert("in error")
      this.setState({
        couponValueErrorMsg: "Field Required",
        couponValueFlag: true,
      });
      this.scrollToOfferValue();
    }
    else if (
      this.state.coupon_transaction_type_id == coupon_transaction_type_id.discount &&
      (this.state.coupon_transaction_subtype_id === 0 ||
      this.state.coupon_transaction_subtype_id === "" ) 
    ) {
      this.setState({
        coupon_transaction_subtype_Flag:true,
      });
      this.scrollToOfferValue();
    }

    else if  (this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y &&(this.state.xquantityFlag || this.state.xquantity === "")) {
      this.setState({
        xquantityErrorMsg: "Field Required",
        xquantityFlag: true,
      });
      this.scrollToCouponQuantity();
      // this.scrollToXYQuantity()  
      }
    else if  (this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y && (this.state.yquantityFlag || this.state.yquantity === "")) {
      this.setState({
        yquantityErrorMsg: "Field Required",
        yquantityFlag: true,
      });
      this.scrollToCouponQuantity();
      // this.scrollToXYQuantity()
        }

    else if (
      this.state.faceValue === "selectType" ||
      this.state.faceValue === ""
    ) {
      this.setState({
        faceValueErrorMsg: "Field Required",
        faceValueFlag: true,
      });
      this.scrollToCouponQuantity();
    } 
 

    else if (
      // this.state.coupon_transaction_type_id == coupon_transaction_type_id.discount &&
      this.state.isCodeEnabled == "1" &&
      (this.state.discountCodeFlag || this.state.coupon_code.trim() === "")
    ) {
      this.setState({
        discountCodeErrorMsg: this.state.coupon_code.trim() === "" ? "Field Required": this.state.discountCodeErrorMsg,
        discountCodeFlag: true,
      });
      // this.scrollToCouponQuantity()
    }
    else if (
      this.state.isCodeEnabled == "2" &&
      (this.state.chooseAFile == "")
    ) {
      this.setState({
        file_error:this.state.file_error == "" ? "Field Required" : this.state.file_error
      });
      
      // this.scrollToCouponQuantity()
    }
    else if (isPrepaid) {
      this.setState({
        costPointsErrorMsg:
          this.state.costPointsFlag && this.state.costPoints.length > 0
            ? this.state.costPointsErrorMsg
            : "Field Required",
        costPointsFlag: true,
      });
      this.scrollToCouponQuantity();
    } else if (this.state.QuantityFlag || this.state.Quantity === "") {
      this.setState({
        QuantityErrorMsg:
          this.state.QuantityFlag && this.state.Quantity.length > 0
            ? this.state.QuantityErrorMsg
            : "Field Required",
        QuantityFlag: true,
      });
      this.scrollToCouponQuantity();
    } 
    
    else if (this.state.startDate === "") {
      this.setState({
        startDateErrorMsg: "Field Required",
        startDateFlag: true,
      });
      this.scrollToUsedStarting();
    } else if (this.state.endDate === "") {
      this.setState({
        endDaterMsg: "Field Required",
        endDateFlag: true,
      });
      this.scrollToUsedStarting();
    } else if (this.state.message === "") {
      this.setState({
        messageErrorMsg: "Field Required",
        messageFlag: true,
      });
      this.scrollToMessage();
    }

    else if (this.state.CouponLogo && this.state.CouponLogo.length < 1) {
      // alert("no coupon images selected")
      this.setState({
        couponImageFlag: true,
      });
      this.scrollToOfferValue();
    }
   
    else if(this.state.showRewardURL && 
      (this.state.rewardURLFlag || this.state.rewardURL.trim() === "")){
      this.setState({
        rewardURLErrorMsg: this.state.rewardURL.trim() === "" ? "Field Required": this.state.rewardURLErrorMsg,
        rewardURLFlag: true,
      });
    }else if(this.state.showWalletURL && 
      (this.state.walletURLFlag || this.state.walletURL.trim() === "")){
      this.setState({
        walletURLErrorMsg: this.state.walletURL.trim() === "" ? "Field Required": this.state.walletURLErrorMsg,
        walletURLFlag: true,
      });
    }
    else if (
      this.state.showURL &&
      (this.state.redemptionURLFlag || this.state.redemptionURL.trim() === "")
    ) {
      this.setState({
        redemptionURLErrorMsg: this.state.redemptionURL.trim() === "" ? "Field Required": this.state.redemptionURLErrorMsg,
        redemptionURLFlag: true,
      });
      // this.scrollToCouponQuantity()
    }
    else if (this.state.isfeaturedOffer && (this.state.featuredOfferOrderFlag || this.state.featuredOfferOrder === "" || !this.state.featuredOfferOrder)) {
      // alert("hello")
      this.setState({
        featuredOfferErrorMsg:
          this.state.featuredOfferOrderFlag
            ? this.state.featuredOfferErrorMsg
            : "Field Required",
        featuredOfferOrderFlag: true,
      });
    }
    else {
      var flag = true;
      if (isPreview) flag = this.validateDatesInPreview();
      valid = flag;
    }
    return valid;
  };

  validateDatesInPreview = () => {
    var flag = false;
    if (this.state.RangeCheck) {

// alert(this.state.canBeUsedStartTimeFlag)
// alert(this.state.canBeUsedStartTime)
      
      if (this.state.canBeUsedStartDate === "") {
        this.setState({
          canBeUsedStartDateErrorMsg: "Field Required",
          canBeUsedStartDateFlag: true,
        });
        this.scrollToCouponYear();
      } 
      else if ((!this.state.canBeUsedStartTime || this.state.canBeUsedStartTime == "Invalid Date") && this.state.canBeUsedStartTimeFlag) {
        // alert("hello")
        this.scrollToCouponYear(); 
        return;
    }
      
      else if (this.state.canBeUsedEndDate === "") {
        this.setState({
          canBeUsedEndDaterMsg: "Field Required",
          canBeUsedEndDateFlag: true,
        });
        this.scrollToCouponYear();
      } 

      
    else if ((!this.state.canBeUsedEndTime || this.state.canBeUsedEndTime == "Invalid Date") && this.state.canBeUsedEndTimeFlag) { 
        this.scrollToCouponYear(); 
        return;
    }
      // else if (this.state.selectedDay && this.checkDaysUnselected()) {
      //   flag = true;
      // } 
      else {
        flag = true;
        // this.scrollToCouponQuantity();
      }
    } else {
      if (this.state.usedOnDate === "") {
        this.setState({
          usedOnDateErrorMsg: "Field Required",
          usedOnDateFlag: true,
        });
        this.scrollToCouponQuantity();
      } else {
        flag = true;
      }
    }
    return flag;
  };
  prevCouponHandler = () => {
    if (this.ValidateFields(true)) {
      this.handleShowCouponPreview();
    }
  };
  capitalize = (text) => {
    var couponTerms = text.trim();
    couponTerms =
    couponTerms.charAt(0).toUpperCase() + couponTerms.substring(1);
    couponTerms = couponTerms.replace(/([^.])$/, "$1.");
    return couponTerms;
  };
  checkDaysUnselected = () => {
    var flag = false;
    if (this.state.showSunday) flag = true;
    else if (this.state.showMonday) flag = true;
    else if (this.state.showTuesday) flag = true;
    else if (this.state.showWednesday) flag = true;
    else if (this.state.showThursday) flag = true;
    else if (this.state.showFriday) flag = true;
    else if (this.state.showSaturday) flag = true;
    else flag = false;

    // if(this.state.showMonday && this.state.showTuesday && this.state.showWednesday && this.state.showThursday && this.state.showMonday && this.state.showFriday && this.state.showSaturday && this.state.showSunday)
    // flag = false;

    this.setState({
      selectedDay: !flag,
    });
    return flag;
  };

  checkDaysSelected = ()=>{
    var days = []
     if (this.state.showMonday) days.push("Monday");
     if (this.state.showTuesday) days.push("Tuesday");
     if (this.state.showWednesday) days.push("Wednesday");
     if (this.state.showThursday) days.push("Thursday");
     if (this.state.showFriday) days.push("Friday");
     if (this.state.showSaturday) days.push("Saturday");
     if (this.state.showSunday) days.push("Sunday");
     if(days.length>0 && days.length <7)
     return days;
     else
     return false
  }

  redemptionURLHandler = (e)=>{
    
      if (validator.isURL(e.target.value)) {
        this.setState({ redemptionURLFlag: false, redemptionURLErrorMsg:"" });
      } else {
        if(e.target.value ==""){
          this.setState({
            redemptionURLFlag: false
          });
        }
        else{
          this.setState({
            redemptionURLFlag: true,
            redemptionURLErrorMsg:"Enter a Valid URL",
          });
        }
      }

      this.setState({ redemptionURL: e.target.value });
    
  }


  rewardURLHandler = (e)=>{
    
    if (validator.isURL(e.target.value)) {
      this.setState({ rewardURLFlag: false, rewardURLErrorMsg:"" });
    } else {
      if(e.target.value ==""){
        this.setState({
          rewardURLFlag: false
        });
      }
      else{
        this.setState({
          rewardURLFlag: true,
          rewardURLErrorMsg:"Enter a Valid URL",
        });
      }
    }

    this.setState({ rewardURL: e.target.value });
  
}

walletURLHandler = (e)=>{
    
  if (validator.isURL(e.target.value)) {
    this.setState({ walletURLFlag: false, walletURLErrorMsg:"" });
  } else {
    if(e.target.value ==""){
      this.setState({
        walletURLFlag: false
      });
    }
    else{
      this.setState({
        walletURLFlag: true,
        walletURLErrorMsg:"Enter a Valid URL",
      });
    }
  }

  this.setState({ walletURL: e.target.value });

}

featuredOfferCheckBoxHandler = () => {

  this.setState({
    isfeaturedOffer: !(this.state.isfeaturedOffer)
  }, () => {
    if (!this.state.isfeaturedOffer)
      this.setState({
        featuredOfferOrder: null,
        featuredOfferOrderFlag: false
      })

  })

}

featuredOfferOrderHandler = (event) => {

  const reg = /^\+?[1-9]\d*$/;

  if(event.target.value === ""){
    this.setState({
      featuredOfferOrderFlag: false,
      featuredOfferOrder: ''
    })
    return
  }

  const validReg = reg.test(event.target.value);
  if (validReg) {
    // this.state.xquantityFlag = false
    this.setState({
      featuredOfferOrderFlag: false
    })

  } else {

    this.state.featuredOfferOrderFlag = true
    this.state.featuredOfferErrorMsg = "Use only positive numbers "

  }

  this.setState({ featuredOfferOrder: event.target.value });
}



handleFiles = files => {
    // console.log(files)
    console.log("fired.");
    let reader = new FileReader();
    let file = files.target.files[0];
    if (file) {
      // var fileName = file.name;
      // var idxDot = fileName.lastIndexOf(".") + 1;
      // var extFile = fileName.substr(idxDot, file.length).toLowerCase();

      let fileTypeError = file.name.endsWith('.csv') ? false: true
      
      if(fileTypeError){
        this.setState({
          file_error:failure_messages.csvFileUploadErrorMsg,
          chooseAFile : '',
          coupon_code_file_url :'',
          isBase64 :''
        })
      }
      else if((file.size/(1024*1024)) > 1){
        this.setState({
          file_error:"File Size should be less than 1 MB"
        })
      }
      else{


        if(!this.isCsvFilenameValid(file)){
          // alert("error in filename")
          this.setState({
            file_error: "File name may includes alphabets, numbers, hyphen and underscore only.",
            chooseAFile: '',
            coupon_code_file_url: '',
            isBase64: ''
          })
          return
        }

  reader.onload = ()=> {
    var textData=reader.result
          var allTextLines = textData.split(/\r\n|\n/);
          var codes = []
          var error=false
          for (let entry of allTextLines) {
            var tmpEntry=""
            // alert(entry)
            if(entry.charAt(0) == "\"")
            {
              for(var i=1; i<entry.length && (entry[i-1]+entry[i])!= "\",";i++)
              tmpEntry=tmpEntry+entry.charAt(i);
            }

            if(tmpEntry != "")
            codes.push(tmpEntry.trim())         
            else 
            codes.push(entry.split(",")[0].trim())
            if(tmpEntry.length > 64 || entry.length>64)
            {
              error="Each offer code should be less than 64 characters"
              break;
            }
          }


          if(codes.length == 1)
          error = 'File does not contain any code except header row'
          else if(codes.length < 1)
          error = 'File does not contain any codes'
          codes.shift()
        
          if(error)
          this.setState({
            file_error:error,
            chooseAFile:""
          })
          else{
          codes = Array.from(new Set(codes))
          var len = codes.length

          if(len < 1){
            this.setState({
              file_error: "File does not contain any code except header row.",
              chooseAFile: '',
              coupon_code_file_url: '',
              isBase64: ''
            })
  
            // this.QuantityHandler({target:{value:len}})
  
            return
          }
  

          if(len > this.state.maxCouponsLimit)
          len = this.state.maxCouponsLimit
          reader = new FileReader();
          reader.onload = ()=> {
            this.state.chooseAFile = file.name
            this.state.coupon_code_file_url = reader.result
            this.state.file_error = ""
            this.state.isBase64 = true
           
            this.QuantityHandler({target:{value:len}})
           
          }
          reader.readAsDataURL(file);
        }
    
      }
      reader.readAsText(file);
    }
    }else{

    }

}

isCsvFilenameValid = (file)=>{

  const reg = /^[a-zA-Z0-9-_ ]*$/;
  let filename = file.name.replace(".csv","")

  console.log("filename", filename)

  if(reg.test(filename))
    return true

  return false
}


  queryStringHandler = (e) =>{
    this.setState({
      queryString: e.target.value,
      //queryStringFlag: false
    });
  }

  showURLHandler = ()=>{
    this.setState({
      showURL: !(this.state.showURL)
    }, ()=>{
      if(!this.state.showURL)
      this.setState({
        showQueryString: false,
        redemptionURL:'',
        queryString:"",
        discountCodeCheckBox:false
      })
      else{
        let company_url =this.props.cookies.get("BusinessProfileData").company_url
     
        this.setState({
          redemptionURL:  company_url?company_url:'https://',
          redemptionURLFlag: company_url?false:true,
          redemptionURLErrorMsg: "Enter a Valid URL",
        })

      } 
    })
  }
  showWalletURLHandler = ()=>{
    this.setState({
      showWalletURL: !(this.state.showWalletURL)
    }, ()=>{
      if(!this.state.showWalletURL)
      this.setState({
        // showQueryString: false,
        walletURL:'',
        walletURLFlag:false,
        // discountCodeCheckBox:false
      })
      else{
        let company_url =this.props.cookies.get("BusinessProfileData").company_url
      
        this.setState({
          walletURL:company_url?company_url:'https://',
          walletURLFlag: company_url?false:true,
          walletURLErrorMsg: "Enter a Valid URL",
        })

      } 
    })
  }
  showRewardURLHandler = ()=>{
    this.setState({
      showRewardURL: !(this.state.showRewardURL)
    }, ()=>{
      if(!this.state.showRewardURL)
      this.setState({
        // showQueryString: false,
        rewardURL:'',
        rewardURLFlag:false
        // queryString:"",
        // discountCodeCheckBox:false
      })
      else{
        let company_url =this.props.cookies.get("BusinessProfileData").company_url
      
        this.setState({
          rewardURL: company_url?company_url:'https://',
          rewardURLFlag: company_url?false:true,
          rewardURLErrorMsg: "Enter a Valid URL",
        })

      } 
    })
  }

  showQueryStringHandler = ()=>{
    this.setState({
      showQueryString: !(this.state.showQueryString)
    })
  }

  DiscountCodeCheckBoxHandler = () =>{
    this.setState({
      discountCodeCheckBox: !(this.state.discountCodeCheckBox)
    })
  }
 
  checkEditData=async ()=>{
    console.log("yearData:",this.state.yearData)
    if(this.state.EditCouponData){
      let data = this.state.EditCouponData
      console.log("EditCouponData:", data)
      if (this.state.yearData.length > 0 && this.state.EditMode && this.state.QuotasContractDetails.length > 0) {
        // alert(JSON.stringify(this.state.yearData))
        this.state.EditMode = false
        this.state.offer_name = data.offer_name
        this.state.xquantity = data.buy_x_quantity
        this.state.yquantity=data.get_y_quantity
        this.state.offerDescription= data.offer_description?data.offer_description:""
        if(parseInt(new Date().getFullYear()) > parseInt(data.year))
        this.setYearQuotas(true)
        else
          this.updateStatesAfterQuotas()
        
        // this.startDateHandler(new Date(new Date(data.redemption_start_date).toISOString().substring(0,10) +" 23:59:59+00:00"))
       
  
        
      }
    }
  }
  updateStatesAfterQuotas=async()=>{

    let data = this.state.EditCouponData
    await this.yearHandler({ target: { value: data.year } })
    this.promotionStartDateHandler(new Date(data.promotion_start_date))
    this.promotionEndDateHandler(new Date(data.promotion_end_date))

    this.state.startTime = new Date(data.promotion_start_date)
    this.state.startTimeWithoutOffset = moment(new Date(data.promotion_start_date)).format("h:mm A")

    this.state.endTime = new Date(data.promotion_end_date)
    this.state.endTimeWithoutOffset = moment(new Date(data.promotion_end_date)).format("h:mm A")




    // alert(data.coupon_transaction_type_id)
    this.state.coupon_transaction_type_id = data.coupon_transaction_type_id
    this.state.coupon_transaction_subtype_id = data.coupon_transaction_subtype_id
    this.state.coupon_transaction_type = { value:data.coupon_transaction_subtype_id?data.coupon_transaction_subtype_id:data.coupon_transaction_type_id}
    // if(data.coupon_transaction_subtype_id)
    // this.state.coupon_transaction_type.isSubType = true

    this.state.needEdit = true
   
    this.state.faceValue = data.coupon_face_value
     this.faceValueDataHandler({ target: { value: data.coupon_face_value } })
     this.state.Quantity = data.coupon_quantity 
     this.state.costPoints = data.cost_in_points 
    this.state.message =data.redemption_message
    if (data.coupon_code && data.coupon_code != "") {
    
      this.setIsCodeEnabled("1")
      this.setDiscountCode({ target: { value: data.coupon_code } })
    }else if(data.coupon_code_file_url && data.coupon_code_file_url!="")
    {
      // this.readDataFromURL(data.coupon_code_file_url)
      this.state.coupon_code_file_url = data.coupon_code_file_url
      this.state.chooseAFile = data.coupon_code_file_url.split("---")[1]
      this.setIsCodeEnabled("2")

    }
    else
    this.setIsCodeEnabled("0")
    if(data.wallet_page_offer_url)
    {
      this.state.showWalletURL=true
      this.state.walletURL = data.wallet_page_offer_url
    }else{
      this.state.showWalletURL=false
      this.state.walletURL=""
    }
    if(data.rewards_page_offer_url)
  {
    this.state.rewardURL = data.rewards_page_offer_url
    this.state.showRewardURL = true
  }else{
    this.state.rewardURL=""
    this.state.showRewardURL=false
  }
  if (data.redemption_url && data.redemption_url != "" && data.redemption_url != "/") {
    var url = data.redemption_url.split("?")
    // alert(JSON.stringify(url))
    this.state.showURL = true
    this.state.redemptionURL = url[0]
    if (url.length > 1) {
      this.state.showQueryString = true
      this.state.queryString = "?" + url[1]
    }
    if (data.show_code_in_redemption_url) {
          this.state.discountCodeCheckBox = true
        }
  }
  else{
    this.state.showURL = false
    this.state.showQueryString=false
    this.state.discountCodeCheckBox=false
  }
  if(data.featured_offer_order){
    this.state.featuredOfferOrder = data.featured_offer_order
    this.state.isfeaturedOffer = true
  }else{
    this.state.featuredOfferOrder=null
    this.state.isfeaturedOffer = false
  }
  this.state.CouponImage = []
    this.state.CouponLogo = []
    let re = /(?:\.([^.]+))?$/;
    // let spl =/(?<=\-\-\-).*/;
    // let fRegex = /(?:\-([^-]+))?$/
    if(data.media_location)
    {
      data.media_location.split(/\;\;\;/).forEach(item => {
        let filename = ''
        if(!item.includes('---'))
        filename =item.split(/\-/).pop()
        else
        filename =item.split(/\-\-\-/).pop()
        
        let ext="."+(re.exec(item)[1])
        this.state.CouponImage.push({value:JSON.stringify({image_location:item}), label:<div><img alt="not found" className={"avatar"} src={Videos.includes(ext)?VideoIcon:item} /> {filename} </div>})
        this.state.CouponLogo.push(JSON.stringify({image_location:item}))
        // map
      })
    }else if(data.image_location && data.image_location != "")
    {
      // this.state.CouponImage = data.image_location
      let item =data.image_location
      let ext="."+(re.exec(item)[1])
      let filename = ''
        if(!item.includes('---'))
        filename =item.split(/\-/).pop()
        else
        filename =item.split(/\-\-\-/).pop()
        
      this.state.CouponImage.push({value:JSON.stringify({image_location:item}), label:<div><img alt="not found" className={"avatar"} src={Videos.includes(ext)?VideoIcon:item} /> {filename} </div>})
      this.state.CouponLogo.push(JSON.stringify({image_location:item}))
    }
    // if (data.image_location && data.image_location != "")
    //   {
    //     this.state.CouponLogo = data.image_location
    //     this.state.CouponImage = data.image_location
    //   }

    if (data.coupon_terms && data.coupon_terms != "")
      this.setRetailerCouponTerm(data.coupon_terms)
    if (data.legalese && data.legalese != "")
      this.setLegalese({ target: { value: data.legalese } })
    if(data.redemption_start_date)
    {
    this.state.showFriday =data.valid_friday
    this.state.showMonday =data.valid_monday
    this.state.showTuesday =data.valid_tuesday
    this.state.showWednesday =data.valid_wednesday
    this.state.showThursday =data.valid_thursday
    this.state.showSaturday =data.valid_saturday
    this.state.showSunday =data.valid_sunday
  
    this.dateRangeRadioHandler()
    this.startDateHandler(new Date(data.redemption_start_date))
    // alert(new Date(data.redemption_end_date).toISOString().substring(0,10))
    this.endDateHandler(new Date(data.redemption_end_date)) 


    this.state.canBeUsedStartTime = new Date(data.redemption_start_date)
    this.state.canBeUsedStartTimeWithoutOffset = moment(new Date(data.redemption_start_date)).format("h:mm A")

    this.state.canBeUsedEndTime = new Date(data.redemption_end_date)
    this.state.canBeUsedEndTimeWithoutOffset = moment(new Date(data.redemption_end_date)).format("h:mm A")

    this.checkDaysUnselected()
    }else{
      // alert(new Date(moment(data.can_only_be_used_on).format("YYYY-MM-DD")+" 23:59:59"))
      this.specificDayRadioHandler() //usedOnDateHandler
      // alert(data.can_only_be_used_on)
      // alert(data.can_only_be_used_on +" 23:59:59")
      this.usedOnDateHandler(new Date(data.can_only_be_used_on +" 23:59:59"))
    }
    
  }
  render() {
    const {
      year,
      yearFlag, 
      yearErrorMsg,
      month,
      monthFlag,
      monthErrorMsg,
      modeType,
      modeTypeFlag,
      modeTypeErrorMsg,
      description,
      descriptionFlag,
      descriptionErrorMsg,
      details,
      detailsFlag,
      detailsErrorMsg,
      message,
      messageFlag,
      messageErrorMsg,

      xquantity,
      xquantityErrorMsg,
      xquantityFlag,
      yquantity,
      yquantityErrorMsg,
      yquantityFlag,
      amountValue,
      amountValueFlag,
      amountValueErrorMsg,
      costPoints,
      costPointsFlag,
      costPointsErrorMsg,
      Quantity,
      QuantityFlag,
      QuantityErrorMsg,
      usedOnDate,
      usedOnDateFlag,
      usedOnDateErrorMsg,
      startDate,
      startDateFlag,
      startDateErrorMsg,
      endDate,
      endDateFlag,
      endDateErrorMsg,
      faceValue,
      faceValueFlag,
      faceValueErrorMsg,
      couponValue,
      couponValueFlag,
      couponValueErrorMsg,
      promotionStartDate,
      promotionStartDateFlag,
      promotionStartDateErrorMsg,
      promotionEndDate,
      promotionEndDateFlag,
      promotionEndDateErrorMsg,
      radioCheck,
      BusinessProfileData,
      coupon_transaction_subtype_Flag,
      coupon_transaction_subtype_ErrorMsg,
      legalese,
      offerDescription,
      showDiscountCodeSection,
      isCodeEnabled,
      startTime,
      startTimeFlag,
      startTimeErrMsg,
      endTimeFlag,
      endTime,
      endTimeErrMsg,
      canBeUsedStartTime,
      canBeUsedStartTimeWithoutOffset,
      canBeUsedStartTimeFlag,
      canBeUsedEndTimeFlag,
      canBeUsedEndTime,
      canBeUsedEndTimeWithoutOffset,
      couponImageFlag,
      couponImageErrorMsg
    } = this.state;

    const is12HourFormat = true
    const mask12HourFormat = "__:__ _M"
    const promotion_start_date=this.state.EditCouponData.promotion_start_date
    const redemption_start_date=this.state.EditCouponData.redemption_start_date
    // alert(this.state.MonthlyQuota)
    // alert(this.state.startDate)
    // alert(this.state.RangeCheck
      // ? startDate === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota =="-"
      //   ? true
      //   : false
      // : true)
// const redemption_end_date = this.state.EditCouponData.redemption_end_date || this.state.EditCouponData.can_only_be_used_on
    return (
      <div className="pricing-box overide-new">
        <div className="container-price">
          <div className="price-box">
            {/* <OverrideCouponsInventoryContainer /> */}
            <h5>Manage Advanced Offers</h5>
          </div>
          <div
            className="pricing-table"
          >
            <OverrideCouponsInventoryContainer setEditCouponData={this.setEditCouponData} resetFaceValue={this.resetFaceValue} cookies={this.props.cookies} />
          </div>
          
          <div className="override-box">

            <div className="override-left">
            <div >
              <div className="ov-single-b">
                <LabelInput
                  label="Offer Title"
                  type="text"
                  name="offer_name"
                  star="*"
                  id="offerTitle"
                  inputValue={this.state.offer_name}
                  errorMsg={this.state.offer_error}
                  flag={this.state.offer_flag}
                  onChange={this.offerHandler}
                  maxLength = {100}
                />
              </div>
              <div className="ov-single-b">
              <LabelTextAreaInput 
              label="Offer Description"
              name="offerDescription"
              id="offerDescription"
              inputValue={offerDescription}
              maxLength={offer_desc_limit}
              placeholder={this.state.descriptionPlaceholder}
              onChange={this.setOfferDescription}
              />
              </div>
              <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.nameCouponOffer = el;
                  }}
                ></div>
              <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}} className="override-two-11">
                <div className="ov-single-b">
                <div className="overide-year des-amnt">
                  <LabelDropDownInput
                    data={this.state.updateActiveMode?[{name:year,value:year}]:this.state.yearData}
                    label="Year"
                    type="year"
                    star="*"
                    name="year"
                    id="year"
                    inputValue={year}
                    flag={yearFlag}
                    errorMsg={yearErrorMsg}
                    onChange={this.yearHandler}
                  />
                  <GetQuotasContractDetailsContainer
                    setYearsQuota={this.setYearsQuota}
                    setUpdateYearQuota={this.setUpdateYearQuota}
                    getYearQuotas = {this.state.getYearQuotas}
                    setYearQuotas={this.setYearQuotas}
                    updateYearQuotas={this.state.updateYearQuotas}
                    // setYearQuotas = (flag)=>{
                    //   this.setState({
                    //     getYearQuotas:flag
                    //   })
                    // }
                    // EditMode={this.state.EditMode || this.state.updateActiveMode}
                    year={year}
                  />
                  </div>
                </div>
                <div className="overde-date-and-time-box">
                <div className="ov-single-b date-over">
                <div className="label-d">Promotion Start Date<span style={{color: "red"}}>*</span></div>
                <DatePicker1
                  htmlFor="promotionStartDate"
                  maxDate={this.state.maximumStartDate}
                  minDate={new Date(this.state.minimunDate)>new Date()?this.state.minimunDate:new Date()}
                  disabled={
                    year === ""
                  }
                  inputValue={startDate}
                  flag={startDateFlag}
                  errorMsg={startDateErrorMsg}
                  onChange={this.promotionStartDateHandler}
                />
              
              </div>
              <div className="time-box-mian2 over-mar">
                      <div className="time-left">
                        <label className="gpp-date-label point">Start Time</label>
                        <div className="time-box">
                          <MaterialTimePicker
                            id="startTime"
                            inputValue={startTime}
                            onChange={this.setStartTime}
                            disabled={
                              year === ""
                            }
                            errorMsg={startTimeFlag ? "Invalid Time" : undefined}
                            mask={mask12HourFormat}
                            ampm={is12HourFormat}
                          />
                          {/* {startTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                        </div>
                      </div>
                </div>
              </div>
              </div>
           </div>
           <div className="overde-date-and-time-box">
                <div className="ov-single-b">
                <div className="overide-year des-amnt">
                
                <div className="date-over-promotion">
                <div className="label-d">Promotion End Date<span style={{color: "red"}}>*</span></div>
                <DatePicker1
                htmlFor="promotionEndDate"
                  maxDate={this.state.maximumDate}
                  minDate={startDate == "" ? new Date() : this.state.EditCouponData.promotion_end_date && new Date(this.state.EditCouponData.promotion_end_date)>new Date() && startDate && this.state.updateActiveMode? new Date(this.state.EditCouponData.promotion_end_date): startDate>new Date()?startDate:new Date()}
                    
                  // minDate={startDate == "" ? new Date() : this.state.EditCouponData.promotion_end_date && new Date(this.state.EditCouponData.promotion_end_date)>new Date()?new Date(this.state.EditCouponData.promotion_end_date):new Date(): startDate}
                    
                  disabled={
                    startDate === ""
                  }
                  inputValue={endDate}
                  flag={endDateFlag}
                  errorMsg={endDateErrorMsg}
                  onChange={this.promotionEndDateHandler}
                />
                </div>
                </div>
                </div>
                <div className="time-box-mian2 over-mar">
                  <div className="time-left">
                    <label className="gpp-date-label point">End Time</label>
                    <div className="time-box">
                      <MaterialTimePicker
                        id="endTime"
                        inputValue={endTime}
                        onChange={this.setEndTime}
                        disabled={
                          year === ""
                        }
                        mask={mask12HourFormat}
                        ampm={is12HourFormat}
            
                        errorMsg={endTimeFlag ? "Invalid Time" : undefined}
                      />
                      {/* {endTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                    </div>
                  </div>
                </div>
              </div>
              <div style={this.state.updateActiveMode ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                <div className="ov-single-b offer-types">
                  <div className="overide-year des-amnt">
                  <GetCouponTypesContainer
                  setCouponType={this.setCouponType}
                  coupon_transaction_type_id={this.state.coupon_transaction_type_id}
                  coupon_transaction_type = {this.state.coupon_transaction_type}
                  coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                  SubTypes={this.state.SubTypes}
                  needEdit={this.state.needEdit}
                  setEditType={this.setEditType}
                  // disabled={
                  //   endDate === "" ? true : false
                  // }
                  couponValueErrorMsg={couponValueErrorMsg}
                  couponValueFlag={couponValueFlag}
                  />
                  {/* {
                    this.state.showSubTypes ?
                  <div className="override-two">
                    <div className="overide-year">
                    {this.state.SubTypes.map((item, index)=>{
                      return (
                      <SingleInputRadio
                      key={index} 
                      label={item.name}
                      name={item.name}
                      checked={ index == this.state.subTypeIndex}
                      // value={item.value}
                      //value={this.state.coupon_transaction_subtype_id}
                      onChange={()=>this.setCouponSubType(item.value, index)}
                      />)
                    })
                  }
                </div>
              </div>
              :
              null
            } */}

                </div>
              </div>
              {
                  coupon_transaction_subtype_Flag ? <p className="li_error_text">Field Required</p> : null
              }

              {/* <div className="ov-feild mid-radio">
                      <SingleInputRadio 
                        label="Percentage Type"
                        onChange={this.percentageRadioHandler}
                        checked={true}
                      />
                      <SingleInputRadio 
                        label="Dollar Type"
                        onChange={this.dollarRadioHandler}
                      />
              </div> */}
 <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.StartDatePromotion = el;
                  }}
                ></div>
{this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?
              <>
<div className="ov-single-b"
  ref={(ref) => this.xquantity = ref}
>
                <LabelInput
                  label="Buying (X) Quantity"
                  type="text"
                  star="*"
                  name="xquantity"
                  id="xquantity"
                  inputValue={xquantity}
                  errorMsg={xquantityErrorMsg}
                  flag={xquantityFlag}
                  onChange={this.xQuantityHandler}
                  // maxLength = {100}
                />
              </div>

              <div className="ov-single-b"
                ref={(ref) => this.xquantity = ref}
              >
                <LabelInput
                  label="Free (Y) Quantity"
                  type="text"
                  name="yquantity"
                  star="*"
                  id="yquantity"
                  inputValue={yquantity}
                  errorMsg={yquantityErrorMsg}
                  flag={yquantityFlag}
                  onChange={this.yQuantityHandler}
                  // maxLength = {100}
                />
              </div>
        </>:""}
           

              <div className="ov-single-b">
              <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.CouponYear = el;
                  }}
                ></div>
                <div className="overide-year des-amnt">
                  <LabelDropDownInput
                    data={this.state.faceValueData}
                    label={this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?"Minimum Purchase Amount":"Offer Value"}
                    type="discount"
                    name="discount"
                    star="*"
                    id="discount"
                    disabled={month == "" ? true : false}
                    inputValue={faceValue}
                    flag={faceValueFlag}
                    errorMsg={faceValueErrorMsg}
                    onChange={this.faceValueDataHandler}
                    coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                    showDollar={true}
                  />
                </div>
                 {
                showDiscountCodeSection && faceValue !=""?
                <Fragment>
              <div className="override-two">
                <div className="ov-single-b">
                <SingleInputRadio
                      key="Generate a unique code for each offer card in the batch"
                      label="Generate a unique code for each offer card in the batch"
                      name="Auto Generated"
                      checked={isCodeEnabled == '0'}
                      // value={item.value}
                      //value={this.state.coupon_transaction_subtype_id}
                      onChange={()=>this.setIsCodeEnabled('0')}
                      id="uniqueOfferCode"
                      />
                <SingleInputRadio
                      key="Use offer code below for all the offer cards in the batch"
                      label="Use offer code below for all the offer cards in the batch"
                      name="Auto Generated"
                      checked={isCodeEnabled == '1'}
                      //checked={index == this.state.subTypeIndex}
                      // value={item.value}
                      //value={this.state.coupon_transaction_subtype_id}
                      onChange={()=>this.setIsCodeEnabled('1')}
                      id="useOfferCode"
                      />
                    </div>

                    <div className="radio-btn-bb-bx txt-left-side op">
                                <SingleInputRadio
                                  key="Upload Offer Codes from CSV file"
                                  label="Upload Offer Codes from CSV file"
                                  name="Auto Generated"
                                  nameinput={"coupon_code_type"}
                                  buttonShow={this.state.buttonShow}
                                  id="couponCodeType"
                                  // onChange={this.buttonHandler}
                                  checked={isCodeEnabled == '2'}
                                  //checked={index == this.state.subTypeIndex}
                                  // value={item.value}
                                  //value={this.state.coupon_transaction_subtype_id}
                                  onChange={() => this.setIsCodeEnabled('2')}
                                  />
                                {this.state.buttonShow && 
                                <i className="fa fa-info-circle icon-info op" data-title2="The system expects all the offer codes to be in the first column. No column header is required."></i>                 
                                 }
                              </div>
                              {/* className="fa fa-info-circle icon-info op" */}
                           

                            <div className="upload-gcmt mt-3">



                                {this.state.buttonShow &&      
                                <><input
                                 id="file-input-id"
                                type="file"
                                // accept=".csv"
                                onClick={(event)=> { 
                                  event.target.value = null
                             }}
                                className="pe-inp-btn-upload"
                                 onChange={this.handleFiles} />
                                      <label
                      htmlFor="file-input-id"
                      // className="pe-upload-btn-upload"
                    >
                        <div className="override-left" style={{width:"100%"}}>
              <div className="upload-hover-button">
                Select File
              </div>
            </div>
                      {/* <div className='camera-upload-hover-upload'>Select File</div>  */}
                   
                      {/* <div className="banner-up">Banner</div> */}
                      {/* <div className="camera-upload-hover-upload">Select</div> */}
                    </label>
                  
                    <span className="ml-3">{this.state.chooseAFile}</span> 
                                 <p className="li_error_text" style={{paddingTop:3}}>{this.state.file_error}</p>
                                </>
                                //  <NewUploadInput
                                //  multiple={false}
                                //  saveUrl = {ROOT_URL+"/api/coupons/upload_coupon_codes"}
                                //  token={getCookie("admin_token_partner")}
                                //  //  MultipleFlag={false}
                                //  //  files={this.state.attachment}
                                //  // flag={this.state.attachmentFlag}
                                //  // errorMsg={this.state.attachmentErrorMsg}
                                //  onChange={this.handleFiles}
                                //  />
                                 }
                                 
                                </div>

                                {/* <button className='btn gc'>Upload Offer Codes</button>  */}
                              {/* <div className="link-gc">
                              {this.state.buttonShow && 
                              <ReactFileReader base64={true} handleFiles={this.handleFiles}  
                              fileTypes={'.csv'}>
                                <div className="upload-gc">Upload Offer Codes</div>
                                <span className="ml-3">{this.state.chooseAFile}</span> 
                              
                                <p className="li_error_text" style={{paddingTop:3}}>{this.state.file_error}</p>
                                </ReactFileReader>
                              }
                              </div> */}


                    </div>
              {/* {this.state.buttonShow && 
                    <div className="ov-single-b upload-op">
             <NewUploadInput />
                  </div>
              
              } */}


              <div className="override-two">
                <div className="overide-year">
                <LabelInput
                    label="Offer Code"
                    type="text"
                    name="Discount Code"
                    star="*"
                    id="discountCode"
                    inputValue={this.state.coupon_code}
                    disabled={isCodeEnabled == '1' ? false: true}
                    flag={this.state.discountCodeFlag}
                    errorMsg={this.state.discountCodeErrorMsg}
                    onChange={this.setDiscountCode}
                    maxLength={64}
                  />
                </div>
              </div>
              </Fragment>
              :
              null
              }
                <div className="overide-year margin">
                  {/* <LabelDropDownInput
                    data={this.state.modeTypeData}
                    label="Mode"
                    type="mode"
                    name="mode"
                    inputValue={modeType}
                    flag={modeTypeFlag}
                    errorMsg={modeTypeErrorMsg}
                    onChange={this.modeHandler}
                  />
                  <GetModeTypesContainer
                    setModeTypeData={this.setModeTypeData}
                  /> */}
                </div>
              </div>
              <div className="override-two-11">
                {/* <div className="overide-year">
                  <LabelInput
                    label="Monthly Quota"
                    type="text"
                    name="MonthlyQuata"
                    inputValue={this.state.MonthlyQuota}
                    disabled={true}

                  />
                </div> */}
                <div className="overide-year margin">
                  <GetPreviousCouponsContainer

                    disabled={true}
                    faceValue={this.state.faceValue}
                    setmaxUnusedQuota={this.setmaxUnusedQuota}
                    year_id={this.state.year_id}
                    selectedMonth={this.state.month}
                    MonthlyQuota={this.state.MonthlyQuota}
                  />
                </div>
              </div>
              <div className="override-two">
                <div className="overide-year">
                  <LabelInput
                    label="Cost in Points"
                    type="text"
                    star="*"
                    name="costPoints"
                    inputValue={costPoints}
                    flag={costPointsFlag}
                    errorMsg={costPointsErrorMsg}
                    onChange={this.costPointsHandler}
                    disabled={
                      faceValue === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota =="-" 
                        ? true
                        : this.state.IsCostDisabled
                    }
                  />
                </div>
                <div className="overide-year margin">
                  <LabelInput
                    label="Max Quantity"
                    type="text"
                    name="Quantity"
                    star="*"
                    id=""
                    inputValue={Quantity}
                    flag={QuantityFlag}
                    errorMsg={QuantityErrorMsg}
                    onChange={this.QuantityHandler}
                    disabled={
                      faceValue === "" || this.state.MonthlyQuota == 0 ||this.state.MonthlyQuota == '-' || this.state.chooseAFile !="" 
                        ? true
                        : false
                    }
                  />
                  <div className="unused-allote">Unused Allotment of Offers: { this.state.maxUnusedQuota} / {this.state.MonthlyQuota}</div>
                </div>
              </div>

              <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.CouponQuantity = el;
                  }}
                ></div>
              
              <div className="ov-single-textarea">
                <LabelTextAreaInput
                  label="Redemption Message"
                  star="*"
                  name="message"
                  id="message"
                  inputValue={message}
                  maxLength={100}
                  flag={messageFlag}
                  errorMsg={messageErrorMsg}
                  onChange={this.messageHandler}
                  disabled={
                    faceValue === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota =="-"
                      ? true
                      : false
                  }
                />
              </div>

              <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.OfferValueScroll = el;
                  }}
                ></div>
                            </div>
              <div className="ov-single-textarea legal">
              <LabelTextAreaInput 
              label="Terms & Conditions (Optional)"
              name="legalese"
              id="legalese"
              inputValue={legalese}
              maxLength={term_and_conditions_limit}
              onChange={this.setLegalese}
              />

                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.UsedMessage= el;
                  }}
                ></div>
              {/* <LabelInput
                    label="legalese (Optional)"
                    type="text"
                    name="legalese"
                    containerStyle={{display:'flex', flexDirection:'column'}}
                    inputStyle={{width:'100%'}}
                    inputContainerStyle={{width:'100%', marginLeft:'0px'}}
                    inputValue={legalese}
                    //disabled={true}
                    // flag={QuantityFlag}
                    // errorMsg={QuantityErrorMsg}
                    onChange={this.setLegalese}
                    placeholder="Enter legalese"
                  /> */}
              </div>
              
              

            
            </div>

            <div className="override-left margin">
              <div className="right-over-box">
              
                <div className="ov-feild radio margin">
                  <SingleInputRadio
                    label="Can be used on date range"
                    checked={this.state.RangeCheck} //SpecificDayCheck
                    onChange={this.dateRangeRadioHandler}
                    disabled={
                      faceValue === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota =="-"
                        ? true
                        : false
                    }
                    id="dateRange"
                    name={"date range"}
                  />
                </div>
                {
                  //this.state.RangeCheck ?
                  <div>
                     <div className="overde-date-and-time-box">
                    <div className="ov-feild date-over">
                      <div className="label-d">Can be used Starting</div>
                      <DatePicker1
                        htmlFor="canBeUsedStarting"
                        maxDate={this.state.usedMaximumDate}
                        // minDate={this.state.updateActiveMode && this.state.EditCouponData.redemption_start_date?new Date(startDate):startDate}
                        minDate={new Date(startDate)>new Date()?new Date(startDate):new Date()}
                        disabled={
                          this.state.RangeCheck
                            ? startDate === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota =="-"
                              ? true
                              : false
                            : true
                        }
                        inputValue={this.state.canBeUsedStartDate}
                        flag={this.state.canBeUsedStartDateFlag}
                        errorMsg={this.state.canBeUsedStartDateErrorMsg}
                        onChange={this.startDateHandler}
                      />
                    </div>
                      <div className="time-box-mian2 over-mar">
                        <div className="time-left">
                          <label className="gpp-date-label point">Start Time</label>
                          <div className="time-box">
                            <MaterialTimePicker
                              id="startTime2"
                              inputValue={canBeUsedStartTime}
                              onChange={this.setCanBeUsedStartTime}
                              disabled={
                                this.state.RangeCheck
                                  ? startDate === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota == "-"
                                    ? true
                                    : false
                                  : true
                              }
                              mask={mask12HourFormat}
                              ampm={is12HourFormat}
                              errorMsg={canBeUsedStartTimeFlag ? "Invalid Time" : undefined}
                            />
                            {/* {startTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overde-date-and-time-box">
                    <div className="ov-feild date-over">
                      <div className="label-d">Can be used Ending</div>
                      <DatePicker1
                       htmlFor="canBeUsedEnding"
                        maxDate={this.state.usedMaximumDate}
            //             minDate={
            //               // endDate == ""
            //               //   ? new Date()
            //               //   : new Date(endDate) >
            //               //     new Date(this.state.canBeUsedStartDate)
            //               //   ? endDateredemption_start_date:moment(this.state.canBeUsedStartDate).format("YYYY-MM-DD")+" 23:59:59",
            // // redemption_end_date:moment(this.state.canBeUsedEndDate).format("YYYY-MM-DD")+" 23:59:59",
            // // can_only_be_used_on: null, this.state.updateActiveMode?new Date(this.state.EditCouponData.endDateredemption_start_date):
            //               //   : 
            //               // minDate={startDate == "" ? new Date() :this.state.updateActiveMode?new Date(this.state.EditCouponData.can_only_be_used_on): startDate}
            //               this.state.updateActiveMode && this.state.EditCouponData.redemption_end_date ? new Date(this.state.EditCouponData.redemption_end_date):this.state.canBeUsedStartDate
            //             }
                        // minDate={startDate == "" ? new Date() : this.state.EditCouponData.promotion_end_date && new Date(this.state.EditCouponData.promotion_end_date)>new Date() && startDate && this.state.updateActiveMode? new Date(this.state.EditCouponData.promotion_end_date): startDate>new Date()?startDate:new Date()}
                  
                        minDate={redemption_start_date && this.state.updateActiveMode && new Date(redemption_start_date)> new Date()?new Date(redemption_start_date):new Date(this.state.canBeUsedStartDate)>new Date()?new Date(this.state.canBeUsedStartDate):new Date()}

                        disabled={
                          this.state.RangeCheck
                            ? this.state.canBeUsedStartDate === ""
                              ? true
                              : endDate === ""
                              ? true
                              : false
                            : true
                        }
                        inputValue={this.state.canBeUsedEndDate}
                        flag={this.state.canBeUsedEndDateFlag}
                        errorMsg={this.state.canBeUsedEndDateErrorMsg}
                        onChange={this.endDateHandler}
                        />
                      </div>
                      <div className="time-box-mian2 over-mar">
                        <div className="time-left">
                          <label className="gpp-date-label point">End Time</label>
                          <div className="time-box">
                            <MaterialTimePicker
                            id="endTime2"
                              inputValue={canBeUsedEndTime}
                              onChange={this.setCanBeUsedEndTime}
                              disabled={
                                this.state.RangeCheck
                                  ? this.state.canBeUsedStartDate === ""
                                    ? true
                                    : endDate === ""
                                      ? true
                                      : false
                                  : true
                              }
                              mask={mask12HourFormat}
                              ampm={is12HourFormat}
                              errorMsg={canBeUsedEndTimeFlag ? "Invalid Time" : undefined}
                            />
                            {/* {endTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ov-feild check-box">
                      <div className="label-check">Valid Days</div>

                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.UsedStarting = el;
                  }}
                ></div>
                      <div className="check-override">
                        <CheckBoxSingle
                          label="Monday"
                          id="monday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showMonday}
                          checked={this.state.showMonday}
                          onChange={this.setMonday}
                        />
                        <CheckBoxSingle
                          label="Tuesday"
                          id="tuesday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showTuesday}
                          checked={this.state.showTuesday}
                          onChange={this.setTuesday}
                        />
                        <CheckBoxSingle
                          label="Wednesday"
                          id="wednesday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showWednesday}
                          checked={this.state.showWednesday}
                          onChange={this.setWednesday}
                        />
                        <CheckBoxSingle
                          label="Thursday"
                          id="thursday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showThursday}
                          checked={this.state.showThursday}
                          onChange={this.setThursday}
                        />
                        <CheckBoxSingle
                          label="Friday"
                          id="friday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showFriday}
                          checked={this.state.showFriday}
                          onChange={this.setFriday}
                        />
                        <CheckBoxSingle
                          label="Saturday"
                          id="saturday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showSaturday}
                          checked={this.state.showSaturday}
                          onChange={this.setSaturday}
                        />
                        <CheckBoxSingle
                          label="Sunday"
                          id="sanday"
                          disabled={!this.state.RangeCheck}
                          value={this.state.showSunday}
                          checked={this.state.showSunday}
                          onChange={this.setSunday}
                        />
                      </div>
                    </div>
                  </div>
                }
                {/* {!this.state.selectedDay ? (
                  <div>
                    <p
                      style={{
                        display: "block",
                        marginTop: 5,
                        paddingTop: 12,
                        fontSize: 12,
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      at least one day should be selected
                    </p>
                  </div>
                ) : null} */}
                <div className="ov-feild mid-radio">
                  <SingleInputRadio
                    label="Can only be used on a specific day"
                    id="specificDay"
                    onChange={this.specificDayRadioHandler} //usedOnDateHandler
                    checked={this.state.SpecificDayCheck} //SpecificDayCheck
                    disabled={this.state.MonthlyQuota == 0 || this.state.MonthlyQuota  == "-"}
                    name={"specific day"}
                  />
                </div>
                {
                  //this.state.SpecificDayCheck ?
                  <div className="ov-single-b date-over">
                    <div className="label-d">Can be used on:<span style={{color: "red"}}>*</span></div>
                    <DatePicker1
                      htmlFor="canBeUsedOnSpecific"
                      maxDate={this.state.usedMaximumDate}
                      minDate={startDate == "" ? new Date() :promotion_start_date && this.state.updateActiveMode&& new Date(promotion_start_date)> new Date()?new Date(promotion_start_date):new Date()}
                      inputValue={usedOnDate}
                      flag={usedOnDateFlag}
                      errorMsg={usedOnDateErrorMsg}
                      onChange={this.usedOnDateHandler}
                      disabled={
                        this.state.SpecificDayCheck
                          ? startDate === "" || this.state.MonthlyQuota == 0 || this.state.MonthlyQuota == "-"
                            ? true
                            : false
                          : true
                      }
                    />
                  </div> //:null
                }
<div >
                {/* <div className="ov-feild date-over"> */}
                  {/* <label className="coupon-trm-l">legalese (Optional)</label> */}
                  {/* <LabelInput
                    label="legalese (Optional)"
                    type="text"
                    name="legalese"
                    containerStyle={{display:'flex', flexDirection:'column'}}
                    inputStyle={{width:'100%'}}
                    inputContainerStyle={{width:'100%', marginLeft:'0px'}}
                    inputValue={legalese}
                    //disabled={true}
                    // flag={QuantityFlag}
                    // errorMsg={QuantityErrorMsg}
                    onChange={this.setLegalese}
                    placeholder="Enter legalese"
                  /> */}
                {/* </div> */}

                <div className="ov-feild date-over">
                <label className="coupon-trm-l">Upload and Select Coupon Images and Videos - Maximum 5<span style={{color: "red"}}>*</span></label>
                  <GetCouponImagesContainer
                    setRetailerCouponImage={this.setRetailerCouponImage}
                    CouponImage={this.state.CouponImage}
                    maxMediaLimit={this.state.maxMediaLimit}
                  />
                </div>
                {
                     couponImageFlag ? <p className="li_error_text">{couponImageErrorMsg}</p> : null
                  }

                  
                <div className="ov-feild date-over" >
                  <label className="coupon-trm-l">
                  Enter Offer Terms (Optional)
                  </label>
                  <GetCouponTermsContainer
                    setRetailerCouponTerm={this.setRetailerCouponTerm}
                    faceValue={this.state.faceValue}
                    TermName={this.state.CouponTerms}
                    coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                    coupon_transaction_type_id={this.state.coupon_transaction_type_id}
                    xquantity={this.state.xquantity}
                    yquantity={this.state.yquantity}
                  />
                </div>


                <div
                    className="featured-offer-checkbox"
                    // style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}
                  >
                    <CheckBoxSingle
                      value={this.state.isfeaturedOffer}
                      onChange={this.featuredOfferCheckBoxHandler}
                      name="Mark Offer as Featured"
                      label="Mark Offer as Featured"
                      id="markOfferFeatured"
                    />

                    {
                      this.state.isfeaturedOffer &&

                      <div className="override-marg"
                        ref={(ref) => this.xquantity = ref}
                      >
                        <div className="ov-feild box-order-input">
                          <LabelInput
                            label="Order"
                            type="text"
                            name="Order"
                            id="order"
                            inputValue={this.state.featuredOfferOrder}
                            //disabled={true}
                            flag={this.state.featuredOfferOrderFlag}
                            errorMsg={this.state.featuredOfferErrorMsg}
                            onChange={this.featuredOfferOrderHandler}
                          // maxLength={100}
                          />
                        </div>
                      </div>
                    }
                  </div>

              <Fragment>
                <div className="ov-feild check-box" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                  <div className="overide-year des-amnt">
                    <div className="check-override">
                      <CheckBoxSingle
                        label="Show different base URL in rewards"
                        //disabled={!this.state.RangeCheck}
                        name="Show Reward URL"
                        id="showRewardBaseURL"
                        value={this.state.showRewardURL}
                        checked={this.state.showRewardURL}
                        onChange={this.showRewardURLHandler}
                      />
                    </div>
                  </div>
                  {
                    this.state.showRewardURL ?
                    <div className="overide-year des-amnt">
                      <LabelInput
                          label=""
                          type="text"
                          name="Reward URL Field"
                          id="RewardURLField"
                          inputValue={this.state.rewardURL}
                          //disabled={isCodeEnabled == '1' ? false: true}
                          flag={this.state.rewardURLFlag}
                          errorMsg={this.state.rewardURLErrorMsg}
                          onChange={this.rewardURLHandler}
                          maxLength={255}
                        />
                    </div>
                    :null
                  }
                  
                </div>

                <div className="ov-feild check-box" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                  <div className="overide-year des-amnt">
                    <div className="check-override">
                      <CheckBoxSingle
                        label="Show different base URL in wallet"
                        //disabled={!this.state.RangeCheck}
                        name="Show Wallet URL"
                        id="ShowWalletBaseURL"
                        value={this.state.showWalletURL}
                        checked={this.state.showWalletURL}
                        onChange={this.showWalletURLHandler}
                      />
                    </div>
                  </div>
                  {
                    this.state.showWalletURL ?
                    <div className="overide-year des-amnt">
                      <LabelInput
                          label=""
                          type="text"
                          name="Wallet URL Field"
                          id="walletURLField"
                          inputValue={this.state.walletURL}
                          //disabled={isCodeEnabled == '1' ? false: true}
                          flag={this.state.walletURLFlag}
                          errorMsg={this.state.walletURLErrorMsg}
                          onChange={this.walletURLHandler}
                          maxLength={255}
                        />
                    </div>
                    :null
                  }
                  
                </div>
                <div className="ov-feild check-box" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                  <div className="overide-year des-amnt">
                    <div className="check-override">
                      <CheckBoxSingle
                        label="Show below URL instead of URL from business profile at time of redemption"
                        //disabled={!this.state.RangeCheck}
                        name="Show URL"
                        id="showURL"
                        value={this.state.showURL}
                        checked={this.state.showURL}
                        onChange={this.showURLHandler}
                      />
                    </div>
                  </div>
                  {
                    this.state.showURL ?
                    <div className="overide-year des-amnt">
                      <LabelInput
                          label=""
                          type="text"
                          name="Redemption URL Field"
                          id="redemptionURLInput"
                          inputValue={this.state.redemptionURL}
                          //disabled={isCodeEnabled == '1' ? false: true}
                          flag={this.state.redemptionURLFlag}
                          errorMsg={this.state.redemptionURLErrorMsg}
                          onChange={this.redemptionURLHandler}
                          maxLength={255}
                        />
                    </div>
                    :null
                  }
                  
                </div>

              {this.state.showURL ?
    <>
              <div className="ov-feild check-box" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                <div className="overide-year des-amnt">
                  <div className="check-override">
                  <CheckBoxSingle
                      label="Show query string at redemption"
                      disabled={!this.state.showURL}
                      value={this.state.showQueryString}
                      onChange={this.showQueryStringHandler}
                      name="Show Query String"
                      id="showQueryString"
                    />
                  </div>
                </div>
                {
                  this.state.showQueryString ?
                  <div className="overide-year des-amnt">
                    <LabelInput
                      label=""
                      type="text"
                      name="Query String"
                      id="queryStringInput"
                      inputValue={this.state.queryString}
                      //disabled={isCodeEnabled == '1' ? false: true}
                      flag={this.state.queryStringFlag}
                      errorMsg={this.state.queryStringErrorMsg}
                      onChange={this.queryStringHandler}
                      maxLength={255}
                    />
                  </div>
                  :null
                }                
              </div>

              
              <div className="ov-feild check-box" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                <div className="overide-year des-amnt">
                  <div className="check-override">
                  <CheckBoxSingle
                      label="Append offer code to URL at redemption"
                      disabled={!this.state.showURL}
                      value={this.state.discountCodeCheckBox}
                      onChange={this.DiscountCodeCheckBoxHandler}
                      name="Append offer code to URL"
                      id="appendOfferCodeURL"
                    />
                  </div>
                </div>
              </div>
              </>:null  }
              </Fragment>
            
</div>
                <div className="btn-box-inv-box">
                  <button
                    id="previewOfferBtn"
                    className="btn-override width"
                    onClick={this.prevCouponHandler}
                  >
                    Preview Offer
                  </button>
                </div>
                <div className="btn-box-inv-box">
                  <button
                  id="generateOfferBtn"
                    className="btn-override width"
                    onClick={()=>this.applyHandler(false)}
                  >
                    Generate Offer
                  </button>
                </div>
                <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                <div className="btn-box-inv-box box-in">
                  <button id="saveDraftBtn" className="btn-override width" onClick={()=>this.applyHandler(true)}>
                    Save As Draft
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-box-override">
            <div className="override-left">
              <div className="btn-previous">
                <button
                  id="backButton4"
                  className="btn-override left"
                  onClick={this.backHandler}
                >
                  <i className="material-icons"> keyboard_arrow_left </i>
                </button>
              </div>
            </div>
            <div className="override-left margin">
              <button id="finishButton4" className="btn-override right" onClick={this.nextHandler}>
                Finish
              </button>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.StartDate = el;
              }}
            ></div>
          </div>
        </div>
        {this.state.showModal ? (
          <Modal
            offer_name={this.state.offer_name}
            image_location={this.state.CouponLogo}
            CouponTerms={this.capitalize(this.state.CouponTerms)}
            coupon_face_value={this.state.faceValue}
            business_name={
              this.props.cookies.get("BusinessProfileData").business_name
            }
            Display_Coupon_Tag={this.state.Display_Coupon_Tag}
            threshold={this.state.threshold}
            Quantity={this.state.Quantity}
            expiryDate={endDate}
            redemption_end_date={this.state.canBeUsedEndDate}
            can_only_be_used_on={this.state.usedOnDate}
            endTimeWithoutOffset={this.state.endTimeWithoutOffset}
            canBeUsedEndTimeWithoutOffset={this.state.canBeUsedEndTimeWithoutOffset}
            points={this.state.costPoints}
            onClose={this.handleCloseModal}
            ValidFor={this.checkDaysSelected()}
            maxUnusedQuota={this.state.maxUnusedQuota}
            business_logo_url={this.props.cookies.get("BusinessProfileData").logo_url}
            logo_url={this.props.cookies.get("BusinessProfileData").logo_url}
            business_street_1={this.props.cookies.get("BusinessProfileData").business_street_1}
            business_street_2={this.props.cookies.get("BusinessProfileData").business_street_2}
            referral_fee_fixed={parseFloat(this.props.cookies.get("BusinessProfileData").referral_fee_fixed)}
            referral_fee_percent={parseFloat(this.props.cookies.get("BusinessProfileData").referral_fee_percent)}
            referral_fee_type_fixed={this.props.cookies.get("BusinessProfileData").referral_fee_type_fixed}
            referral_fee_type_percent={this.props.cookies.get("BusinessProfileData").referral_fee_type_percent}
            display_address_on_coupon={this.props.cookies.get("BusinessProfileData").display_address_on_coupon}
            coupon_transaction_type_id={this.state.coupon_transaction_type_id}
            coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
            legalese={this.state.legalese}
            offerDescription={this.state.offerDescription}
            city={this.props.cookies.get("BusinessProfileData").city}
            state={this.props.cookies.get("BusinessProfileData").state}
            zip5={this.props.cookies.get("BusinessProfileData").zip5}
            zip4={this.props.cookies.get("BusinessProfileData").zip4}
            company_url={this.props.cookies.get("BusinessProfileData").company_url}
            business_description={this.state.offerDescription.trim()!==""?this.state.offerDescription:this.props.cookies.get("BusinessProfileData").description}
            RewardURL={this.state.rewardURL.trim()!==""?this.state.rewardURL:false}
            WalletURL={this.state.walletURL.trim()!==""?this.state.walletURL:false}
            redemption_url={this.state.redemptionURL}
            coupon_code={this.state.coupon_code}
            showRedemptionUrl={this.state.showURL}
            showQueryString={this.state.showQueryString}
            queryString={this.state.queryString}
            show_code_in_redemption_url={this.state.discountCodeCheckBox}
            coupon_display_behavior_id={this.props.cookies.get("BusinessProfileData").coupon_display_behavior_id}
            coupon_display_behavior={this.props.cookies.get("BusinessProfileData").coupon_display_behavior}
            redemption_message={message}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(OverridePricing);
